import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    SET_PARTICIPANT: (s, data) => (s.data = data),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, { compId, limit, page }) {
      commit('RESET')
      commit('LOADING')
      try {
        const params = { limit, page }
        const res = await this.$api.get(`/bo/sdp-comps/comp/${compId}/participant`, { params })
        const participantData = res.data.data
        const { total } = res.data.pagination
        if (!participantData) return
        commit('SET_TOTAL_PAGE', total)
        commit('SET_PARTICIPANT', participantData)
        return participantData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
