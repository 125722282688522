import { apiV2 } from '@/services/api'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingUpdateStatus: false,
  loadingApproveAll: false,
  loadingNote: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingUpdateStatus = true),
    LOADING_APPROVE_ALL: state => (state.loadingApproveAll = true),
    LOADING_NOTE: state => (state.loadingNote = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loadingUpdateStatus = false),
    LOADED_APPROVE_ALL: s => (s.loadingApproveAll = false),
    LOADED_NOTE: s => (s.loadingNote = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async updateWithdrawStatus({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const { status, id } = payload
        const res = await this.$api.put(`/portal/withdrawal-requests/${id}`, {
          status,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateWithdrawUPCOZStatus({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const { status, id, type } = payload
        const res = await apiV2.put(`/v2/portal/withdrawal-requests/${id}/status`, {
          status, type,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateWithdrawStatusList({ commit, dispatch }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const { status, withdrawalRequestIds } = payload
        const res = await this.$api.put('/portal/withdrawal-requests/status/bulk', {
          status,
          withdrawalRequestIds,
        })
        if (res.data.data) {
          dispatch('transaction/withdrawList/getListWithdraw', null, { root: true })
        }
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateWithdrawApproveAll({ commit }, payload) {
      commit('RESET')
      commit('LOADING_APPROVE_ALL')
      try {
        const {
          status, newStatus, startDate, endDate,
        } = payload
        const res = await this.$api.put('/portal/withdrawal-requests/status/by-status', {
          status,
          newStatus,
          ...(startDate
            && endDate && {
            createdTime: {
              startDate,
              endDate,
            },
          }),
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_APPROVE_ALL')
      }
    },
    async updateNote({ commit }, payload) {
      commit('LOADING_NOTE')
      try {
        const { note, id } = payload
        const res = await this.$api.put(`/portal/withdrawal-requests/${id}`, {
          notes: note,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_NOTE')
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
  },
}
