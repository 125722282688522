import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingAddCode: false,
  loadingAddUser: false,
  loadingGetUser: false,
  total: null,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD_ADD_CODE: (state, data) => (state.loadingAddCode = data),
    LOAD_ADD_LIST_USER: (state, data) => (state.loadingAddCode = data),
    LOAD_GET_LIST_USER: (state, data) => (state.loadingGetUser = data),
    SET_TOTAL: (state, data) => (state.total = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async handleAddCode({ commit }, payload) {
      commit('LOAD_ADD_CODE', true)
      try {
        const { competitonId, code, isEnabledWhitelistUser } = payload
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          code,
          isEnabledWhitelistUser,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_ADD_CODE', false)
      }
    },
    async handleAddUserCompCode({ commit }, payload) {
      commit('LOAD_ADD_LIST_USER', true)
      try {
        const { competitonId, userIds } = payload
        const res = await this.$api.post(`/portal/competitions/${competitonId}/competition-advance/whitelist`, {
          userIds,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_ADD_LIST_USER', false)
      }
    },
    async getListUserInWhiteList({ commit }, payload) {
      commit('LOAD_GET_LIST_USER', true)
      try {
        const res = await this.$api.get(`/portal/competitions/${payload.competitionId}/competition-advance/whitelist`, {
          params: {
            ...payload,
          },
        })
        if (!res.data.data) return
        const total = res.data.pagination.total
        commit('SET_TOTAL', total)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_GET_LIST_USER', false)
      }
    },
    async handleRemoveUserCompCode({ commit }, payload) {
      commit('LOAD_ADD_LIST_USER', true)
      try {
        const { competitonId, userIds } = payload
        const res = await this.$api.delete(`/portal/competitions/${competitonId}/competition-advance/whitelist`, {
          params: { 'userIds[]': userIds },
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_ADD_LIST_USER', false)
      }
    },
  },
}
