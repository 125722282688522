import { errorHandler } from '@/services/toastHandler'

export default {
  state: {
    loading: false,
    error: '',
  },

  mutations: {
    LOADING: s => {
      s.loading = true
      s.error = ''
    },
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async do({ commit }, payload) {
      commit('LOADING')
      try {
        const { isUPCAccount, id } = payload
        await this.$api.put(`/bo/users/profile/${id}`, {
          isUPCAccount,
        })
      } catch (e) {
        const error = e || 'Error show user on search!'
        commit('SET_ERROR', error)
        errorHandler(error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
