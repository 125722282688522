import { formatRole, getAbility } from '@/helpers/permission'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: {},
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async updateAccountAsync({ commit, dispatch, rootState }, { id, avatar, body }) {
      commit('RESET')
      commit('LOADING')
      try {
        if (Object.keys(body).length > 0) {
          const { data: response } = await this.$api.put(`/bo/users/profile/${id}`, body)
          const formatData = {
            ...response.data,
            role: formatRole(response.data.roleId),
            accessToken: rootState.auth.accessToken,
          }
          dispatch('updateAuth', formatData)
        }

        if (avatar && avatar.get('image') !== 'null') {
          await dispatch('updateAvatar', { id, avatar })
          return true
        }
        return true
      } catch (e) {
        commit('SET_ERROR', e)
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateAvatar({ commit, dispatch, rootState }, { id, avatar }) {
      commit('LOADING')
      try {
        const { data: response } = await this.$api.put(`/bo/users/profile/${id}`, avatar)

        const formatData = {
          ...response.data,
          role: formatRole(response.data.roleId),
          accessToken: rootState.auth.accessToken,
        }

        dispatch('updateAuth', formatData)
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateAuth({ dispatch }, data) {
      dispatch('auth/setAbility', getAbility(data.role), { root: true })
      dispatch('auth/setAuth', data, { root: true })
    },
  },
}
