import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  loadingFrontPrize: false,
  loadingBackPrize: false,

  listPrizes: [],
  error: '',
  total: 0,
  totalFrontPrizes: 0,
  totalBackPrizes: 0,
  isApprovedTop10: false,
  isApprovedTop10Front: false,
  isApprovedTop10Back: false,
  isApprovedExceptTop10: false,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOADING: s => (s.loading = true),
    LOADING_FRONT_PRIZE: s => (s.loadingFrontPrize = true),
    LOADING_BACK_PRIZE: s => (s.loadingBackPrize = true),
    SET_TOTAL_FRONT_PRIZE: (s, total) => (s.totalFrontPrizes = total),
    SET_TOTAL_BACK_PRIZE: (s, total) => (s.totalBackPrizes = total),

    SET_TOTAL_PAGE: (s, total) => (s.total = total),
    SET_IS_APPROVE_TOP_10: (s, isApprovedTop10) => (s.isApprovedTop10 = isApprovedTop10),
    SET_IS_APPROVE_TOP_10_FRONT: (s, isApprovedTop10Front) => (s.isApprovedTop10Front = isApprovedTop10Front),
    SET_IS_APPROVE_TOP_10_BACK: (s, isApprovedTop10Back) => (s.isApprovedTop10Back = isApprovedTop10Back),

    SET_IS_APPROVE_EXCEPT_TOP_10: (s, isApprovedExceptTop10) => (s.isApprovedExceptTop10 = isApprovedExceptTop10),

    SET_DATA_LIST_PRIZE: (s, data) => (s.listPrizes = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    LOADED_FRONT_PRIZE: s => (s.loadingFrontPrize = false),
    LOADED_BACK_PRIZE: s => (s.loadingBackPrize = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListPrizes({ commit }, {
      competitionId, page, limit, username,
    }) {
      commit('LOADING')
      try {
        const params = {
          limit,
          page,
          competitionId,
          withButtonStatus: 1,
          username,
        }
        const res = await this.$api.get('/portal/competition-users', { params })
        const prizesData = res.data.competitionUsers
        const { total } = res.data.pagination
        commit('SET_DATA_LIST_PRIZE', prizesData)
        commit('SET_IS_APPROVE_TOP_10', res.data.button.approveTop10Rank)
        commit('SET_IS_APPROVE_EXCEPT_TOP_10', res.data.button.approveExceptTop10Rank)

        commit('SET_TOTAL_PAGE', total)
        return prizesData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async getListFrontPrize({ commit }, {
      competitionId, page, limit, username,
    }) {
      commit('LOADING_FRONT_PRIZE')

      try {
        const params = {
          limit,
          page,
          competitionId,
          entryType: 0,
          hasRank: 1,
          withButtonStatus: 1,
          username,

        }
        const res = await this.$api.get('/entry-leaderboard/live', { params })
        const prizesData = res?.data?.competitionUsers
        if (prizesData?.length) {
          commit('SET_TOTAL_FRONT_PRIZE', res?.data?.pagination?.total)
          commit('SET_IS_APPROVE_TOP_10_FRONT', res.data.button.approveTop10Rank)

          return prizesData
        }
        return []
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_FRONT_PRIZE')
      }
    },

    async getListBackPrize({ commit }, {
      competitionId, page, limit, username,
    }) {
      commit('LOADING_BACK_PRIZE')

      try {
        const params = {
          limit,
          page,
          competitionId,
          entryType: 1,
          hasRank: 1,
          withButtonStatus: 1,
          username,

        }
        const res = await this.$api.get('/entry-leaderboard/live', { params })
        const prizesData = res?.data?.competitionUsers
        if (prizesData?.length) {
          commit('SET_TOTAL_BACK_PRIZE', res?.data?.pagination?.total)
          commit('SET_IS_APPROVE_TOP_10_BACK', res.data.button.approveTop10Rank)
          return prizesData
        }
        return []
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_BACK_PRIZE')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
