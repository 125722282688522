import list from './list'
import detail from './detail'
import reportList from './report-list'
import reportDetailList from './report-detail-list'
import ban from './ban'
import deleteHowl from './delete'
import listSport from './list-sport'
import create from './create'

const postsModule = {
  namespaced: true,
  modules: {
    list,
    reportList,
    detail,
    reportDetailList,
    ban,
    deleteHowl,
    listSport,
    create,
  },
}

export default postsModule
