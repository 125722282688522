import { errorHandler } from '@/services/toastHandler'
import Axios from 'axios'

const initState = () => ({
  loading: false,
  error: {},
  success: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    SUCCESSED: s => (s.success = true),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async do({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const baseURL = 'https://api.ipify.org?format=json'
        const api = Axios.create({ baseURL })
        const { data } = await api.get()
        const ipAddress = data.ip
        await this.$api.post('/bo/users/sign-up', {
          ...payload,
          userIp: ipAddress,
        })
        commit('SUCCESSED')
        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
