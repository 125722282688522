import { errorHandler } from '@/services/toastHandler'

export default {
  namespaced: true,

  state: {
    loading: false,
    error: '',
  },

  mutations: {
    LOADING: s => {
      s.loading = true
      s.error = ''
    },
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async deleteComp({ commit }, compId) {
      commit('LOADING')

      try {
        const { data: response } = await this.$api.delete(`/bo/sdp-comps/comp/${compId}`)
        return response.data
      } catch (e) {
        const error = e || 'Error deleting comp!'
        errorHandler(error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
