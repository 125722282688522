import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingShowFlexiAmount: false,
  loadingChangeBetslipMode: false,
  loadingMaximumStake: false,
  loadingShowQPLegs: false,
  loadingBAY: false,
  total: null,
  error: '',
  clientProfileList: [],
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD_SHOW_FLEXI: (state, data) => (state.loadingShowFlexiAmount = data),
    LOAD_CHANGE_BETSLIP_MODE: (state, data) => (state.loadingChangeBetslipMode = data),
    LOAD_CHANGE_MAXIMUM_STAKE: (state, data) => (state.loadingMaximumStake = data),
    LOAD_SHOW_QP_LEGS: (state, data) => (state.loadingShowQPLegs = data),
    LOAD_SHOW_BAY: (state, data) => (state.loadingBAY = data),
    SET_LIST_PROFILE: (state, data) => (state.clientProfileList = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async handleShowFlexiAmount({ commit }, payload) {
      commit('LOAD_SHOW_FLEXI', true)
      try {
        const { competitonId, isFlexiLeaderboardVisible } = payload
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          isFlexiLeaderboardVisible,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SHOW_FLEXI', false)
      }
    },
    async checkBetSlipMode({ commit }, payload) {
      commit('LOAD_CHANGE_BETSLIP_MODE', true)
      try {
        const { competitonId, betSlipMode } = payload
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          betSlipMode,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHANGE_BETSLIP_MODE', false)
      }
    },
    async checkMaximumStake({ commit }, payload) {
      commit('LOAD_CHANGE_MAXIMUM_STAKE', true)
      try {
        const { competitonId, maximumBetSlipStake } = payload
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          maximumBetSlipStake,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHANGE_MAXIMUM_STAKE', false)
      }
    },
    async handleShowQPLegsBtn({ commit }, payload) {
      commit('LOAD_SHOW_QP_LEGS', true)
      try {
        const { competitonId, isDisabledQuickPickLeg } = payload
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          isDisabledQuickPickLeg,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SHOW_QP_LEGS', false)
      }
    },
    async handleChangeSettingBAY({ commit }, payload) {
      commit('LOAD_SHOW_BAY', true)
      try {
        const { competitonId, betYourselfSetting } = payload
        const obj = { betYourselfSetting } || { betYourselfSetting: null }
        const res = await this.$api.put(`/portal/competitions/${competitonId}/competition-advance`, {
          ...obj,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SHOW_BAY', false)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getProfileType({ commit }) {
      try {
        const res = await this.$api.get('/profile-types/')
        if (!res.data.data) return
        const formatList = res.data.data.map(i => i.profileTypeId)
        commit('SET_LIST_PROFILE', formatList)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },
  },
}
