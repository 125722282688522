import vuexPersist from '@/store/vuex-persist'
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import account from './account'
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import createAccount from './create-account'
import dashboard from './dashboard'
import post from './post'
import transaction from './transaction'
import trendingFeed from './trending-feed'
import user from './user'
import verticalMenu from './vertical-menu'
import sdpAccount from './sdp-account'
import competition from './competition'
import racing from './racing'
import races from './races'
import setting from './setting'
import notification from './notification'
import sportComp from './sport-comp'
import tipping from './tipping'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    account,
    createAccount,
    user,
    sdpAccount,
    dashboard,
    post,
    transaction,
    appConfig,
    verticalMenu,
    trendingFeed,
    competition,
    racing,
    races,
    setting,
    notification,
    sportComp,
    tipping,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
  plugins: [vuexPersist.plugin],
})
