import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  error: '',
  loadingGetData: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD: (state, data) => (state.loadingGetData = data),
  },

  actions: {
    async getSetting(_, params) {
      try {
        const res = await this.$api.get(`/portal/settings/${params}`)
        return res.data.data.value
      } catch (e) {
        errorHandler(e)
      }
    },
    async setNewSetting(_, params) {
      try {
        const res = await this.$api.put(`/portal/settings/${params.key}`, {
          value: params.number,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },
    async setNewSettingFreeQuickPick({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.put('/portal/settings/flash-banner-for-free-quick-pick', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async setNewSettingNotiEvent({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.put('/portal/settings/format-notif-event-upcoming', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async setNewSettingNotiEventTipping({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.put('/portal/settings/format-notif-event-upcoming-tipping', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async setNewSettingInviteJoinGroup({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.put('/portal/settings/format-notif-invite-join-group', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async setNewSettingUpdateProfileBetBack(_, params) {
      const { key, ...rest } = params
      try {
        const res = await this.$api.put(`/portal/settings/${params.key}`, {
          ...rest.data,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },
  },
}
