import list from './list'
import setAnchor from './setAnchor'
import setTrending from './setTrending'

const trendingFeedModule = {
  namespaced: true,
  modules: {
    list,
    setAnchor,
    setTrending,
  },
}

export default trendingFeedModule
