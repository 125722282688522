import list from './list'
import listAccount from './list-account'
import search from './search'
import changePosition from './change-position'
import changeSport from './change-sport'

const sdpAccountModule = {
  namespaced: true,
  modules: {
    list,
    listAccount,
    search,
    changePosition,
    changeSport,
  },
}

export default sdpAccountModule
