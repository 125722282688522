import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
  totalPage: 0,
  data: {
    users: 0,
    buyInBalance: 0,
    payout: 0,
    depositBalance: 0,
    createdCompetitions: 0,
  },
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_DATA: (s, data) => (s.data = { ...data }),
    LOADING: s => (s.loading = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async load({ commit }, params) {
      commit('LOADING')
      try {
        const res = await this.$api.get(`/portal/dashboard?dateFilter[startDate]=${params.startDate}&dateFilter[endDate]=${params.endDate}`)
        commit('SET_DATA', res.data.data)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
  },
}
