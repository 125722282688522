import detail from './detail'

const dashboardModule = {
  namespaced: true,
  modules: {
    detail,
  },
}

export default dashboardModule
