import { errorHandler } from '@/services/toastHandler'

const state = () => ({
  loading: false,
  error: '',
  data: '',
})

export default {
  namespaced: true,
  state: state(),

  mutations: {
    SET_DATA: (s, data) => s.data = data,
    LOADING: s => Object.assign(s, state(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async recaptchaAsync({ commit }, token) {
      commit('LOADING')
      try {
        if (!token) throw new Error('Token captcha not found')
        const params = { response: token }
        const { data: res } = await this.$api.get('/users/verify-captcha', { params })
        if (res.error) return
        return res.data.success
      } catch (error) {
        errorHandler(error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
