import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  listSport: [],
  loading: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_LISTSPORT: (s, listSport) => (s.listSport = listSport),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const params = {
          page: 1,
          limit: 99,
          ...(payload ? { isRegister: payload.isRegister } : null),
        }
        const res = await this.$api.get('/sports/list', { params })

        const dataListSport = res.data.data

        commit('SET_LISTSPORT', dataListSport)
        return dataListSport
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
