import { getAbility } from '@/helpers/permission'
import { errorHandler } from '@/services/toastHandler'

const state = () => ({
  loading: false,
  error: '',
})

export default {
  namespaced: true,
  state: state(),

  mutations: {
    LOADING: s => Object.assign(s, state(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async loginAsync({ commit, dispatch }, payload) {
      commit('LOADING')
      try {
        const { data: response } = await this.$api.post('/users/sign-in', payload)

        const data = response.data
        if (!data.isSuperAdmin) return errorHandler('Login failed!')

        const formatData = {
          ...response.data,
          role: 'admin',
        }

        dispatch('auth/setAbility', getAbility(formatData.role), { root: true })
        dispatch('auth/setAuth', formatData, { root: true })
        return formatData
      } catch (error) {
        errorHandler(error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
