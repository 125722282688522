const initState = () => ({
  racingList: [],
  loading: false,
  eventPicked: {},
})

export default {
  namespaced: true,
  state: initState(),
  getters: {
    filterRacing(state) {
      return filterInfo => {
        if (filterInfo.status === 'All') {
          return state.racingList.filter(race => race.name.toLowerCase().includes(filterInfo.name.toLowerCase()))
        }
        return state.racingList.filter(
          // eslint-disable-next-line comma-dangle
          race => race.name.toLowerCase().includes(filterInfo.name.toLowerCase()) && race.status === filterInfo.status
        )
      }
    },
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    RESET_STATE: state => (state = initState()),
    SET_RACINGS: (state, data) => (state.racingList = [...data]),
    SET_EVENT_PICKED: (state, data) => (state.eventPicked = data),
    // eslint-disable-next-line no-unused-vars
    LOADING: state => (state.loading = true),
    // eslint-disable-next-line no-unused-vars
    LOADED: state => (state.loading = false),
  },
  actions: {
    getRacingList: ({ commit }) => {
      const data = [
        {
          id: '00000001',
          name: 'RANDWICK',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Expired',
        },
        {
          id: '00000002',
          name: 'FLEMINGTON',
          date: '26/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000003',
          name: 'DOOMBEN',
          date: '27/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000004',
          name: 'BELMONT',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Coming Soon',
        },
        {
          id: '00000005',
          name: 'GAWLER',
          date: '21/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000001',
          name: 'RANDWICK',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000002',
          name: 'FLEMINGTON',
          date: '22/06/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000003',
          name: 'DOOMBEN',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000004',
          name: 'BELMONT',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
        {
          id: '00000005',
          name: 'GAWLER',
          date: '22/07/2023',
          code: 'T',
          time: '12:30pm',
          race: '17:55pm',
          country: 'AUSTRALIA',
          status: 'Live',
        },
      ]
      commit('LOADING')
      setTimeout(() => {
        commit('SET_RACINGS', data)
        commit('LOADED')
      }, 2000)
      return data
    },
    getRacingDetails: ({ commit }) => {
      const data = [
        {
          id: 16283712,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'False',
        },
        {
          id: 16283713,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Furphy Maiden',
          coupon: 'False',
        },
        {
          id: 1628371,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Amelia Park',
          coupon: 'False',
        },
        {
          id: 16283715,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Perth Racing Cl',
          coupon: 'True',
        },
        {
          id: 16283716,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Tabtouch - Have',
          coupon: 'True',
        },
        {
          id: 16281712,
          date: '22/07/2023',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'False',
        },
        {
          id: 16212713,
          date: '',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'False',
        },
        {
          id: 16253714,
          date: '',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'False',
        },
        {
          id: 1638371,
          date: '',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'True',
        },
        {
          id: 16213716,
          date: '',
          time: '',
          comp: '',
          leg: '',
          result: '',
          name: 'Morley Growers',
          coupon: 'True',
        },
      ]

      commit('LOADING')
      commit('LOADED')
      return data
    },
    getEventDetails: ({ commit }) => {
      const data = [
        {
          no: 1,
          silk: '-',
          name: 'Bondi Plunge',
          win: '3.70',
          place: '1.85',
          is: 'FALSE',
          toteWin: '',
          totePlace: '',
          placing: '',
        },
        {
          no: 2,
          silk: '-',
          name: 'Dubai s Daughter',
          win: '3.70',
          place: '1.75',
          is: 'FALSE',
          toteWin: '',
          totePlace: '',
          placing: '',
        },
        {
          no: 3,
          silk: '-',
          name: 'Lady Ducati',
          win: '101.00',
          place: '19.00',
          is: 'FALSE',
          toteWin: '',
          totePlace: '',
          placing: '',
        },
        {
          no: 4,
          silk: '-',
          name: 'Platinum Shot',
          win: '3.00',
          place: '1.60',
          is: 'FALSE',
          toteWin: '',
          totePlace: '',
          placing: '',
        },
        {
          no: 5,
          silk: '-',
          name: 'Stormchaser',
          win: '5.00',
          place: '2.20',
          is: 'FALSE',
          toteWin: '',
          totePlace: '',
          placing: '',
        },
      ]

      commit('LOADING')
      commit('LOADED')
      return data
    },
    setPickEvent: ({ commit }, params) => {
      commit('SET_EVENT_PICKED', params)
    },
  },
}
