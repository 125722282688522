const state = () => ({
  accessToken: '',
  user: '',
  ability: '',
  error: null,
})

export default {
  namespaced: true,

  state: state(),

  getters: {
    isLoggedIn(s) {
      return !!s.accessToken
    },
  },

  mutations: {
    SET_AUTH: (s, payload) => {
      s.accessToken = payload.accessToken
      s.user = payload
    },
    SET_ABILITY: (s, payload) => (s.ability = payload),
    PURGE_AUTH: s => Object.assign(s, state()),
  },

  actions: {
    setAuth({ commit }, payload) {
      commit('SET_AUTH', payload)
    },
    setAbility({ commit }, payload) {
      commit('SET_ABILITY', payload)
    },
    purgeAuth({ commit, dispatch }) {
      dispatch('races/createRace/reset', null, { root: true })
      commit('PURGE_AUTH')
    },
  },
}
