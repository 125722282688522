import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: {},
  loadingGetBonusCash: false,
  bonusCashForUser: 0,
  loadingGetBonusCashHistory: false,
  loadingGetBonusCashAvailable: false,
  listBonusOfferByUser: [],
  selectedOfferDeposit: null,
  selectedOfferDirect: null,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => (s.loading = true),
    LOADING_GET_BONUS: s => (s.loadingGetBonusCash = true),
    LOADING_GET_BONUS_HISTORY: s => (s.loadingGetBonusCashHistory = true),
    SET_ERROR: (s, error) => (s.error = error),
    SET_DATA: (s, data) => (s.bonusCashForUser = data),
    LOADED: s => (s.loading = false),
    LOADED_GET_BONUS: s => (s.loadingGetBonusCash = false),
    LOADED_GET_BONUS_HISTORY: s => (s.loadingGetBonusCashHistory = false),
    SET_DATA_OFFER_USER: (s, data) => (s.listBonusOfferByUser = data),
    LOAD_GET_BONUS_AVAILABLE: (s, data) => (s.loadingGetBonusCashAvailable = data),
    SET_SELECT_OFFER_DEPOSIT: (s, data) => (s.selectedOfferDeposit = data),
    SET_SELECT_OFFER_DIRECT: (s, data) => (s.selectedOfferDirect = data),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async setBonusCash({ commit }, payload) {
      commit('LOADING')
      try {
        const { bonusOfferId, data } = payload
        const res = await this.$api.post(`/portal/bonus-offers/${bonusOfferId}/bonus/add`, data)
        if (res?.data) { return true }
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async getBonusCash({ commit }, params) {
      commit('LOADING_GET_BONUS')
      try {
        const res = await this.$api.get(`/portal/bonus-offers/bonus/user/${params}?shouldOnlySum=1`)
        commit('SET_DATA', typeof res.data.data === 'number' ? res.data.data : 0)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_GET_BONUS')
      }
    },
    async getBonusCashHistory({ commit }, params) {
      commit('LOADING_GET_BONUS_HISTORY')
      try {
        const res = await this.$api.get('/portal/bonus-offers/bonus', { params })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_GET_BONUS_HISTORY')
      }
    },
    async getBonusOfferUser({ commit }, params) {
      commit('LOAD_GET_BONUS_AVAILABLE', true)
      try {
        const res = await this.$api.get('/portal/bonus-offers/available-offers', { params })
        const list = res.data.data
        if (list.length === 0) {
          return list
        }
        const newList = list.map(i => ({
          id: i.bonusOfferId,
          label: i.description,
        }))
        commit('SET_DATA_OFFER_USER', newList)

        return list
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_GET_BONUS_AVAILABLE', false)
      }
    },
    setSelectOfferDeposit: ({ commit }, params) => {
      commit('SET_SELECT_OFFER_DEPOSIT', params)
    },
    setSelectOfferDirect: ({ commit }, params) => {
      commit('SET_SELECT_OFFER_DIRECT', params)
    },
  },
}
