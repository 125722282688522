import limitNumber from './limit-betslip'
import setting from './setting'

const settingModule = {
  namespaced: true,
  modules: {
    limitNumber,
    setting,
  },
}

export default settingModule
