import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    SET_LISTCOMP: (s, data) => (s.data = data),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, { compType, page, limit }) {
      commit('RESET')
      commit('LOADING')
      try {
        const params = {
          limit,
          page,
          compType,
        }
        const res = await this.$api.get('/bo/sdp-comps/comp', { params })
        const compData = res.data.data
        const { total } = res.data.pagination
        commit('SET_LISTCOMP', compData)
        commit('SET_TOTAL_PAGE', total)
        return compData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
