import listRace from './list-race'
import createRace from './create-race'
import listComp from './list-comp'
import compDetail from './comp-detail'
import updateComp from './update-comp'
import listLeaderboard from './list-leaderboard'
import prizeTransfer from './prize-transfer'
import endComp from './end-comp'
import listCampaign from './list-campaign'
import bonusCash from './bonus-card'
import flexiRollOver from './flexi-roll-over'
import listGroup from './list-group'
import freeBetslip from './free-betslip'
import list from './list'
import entryCode from './entry-code'
import setting from './setting'
import jackpot from './jackpot'
import sport from './sport'
import listBack from './list-back'

const racesModule = {
  namespaced: true,
  modules: {
    listRace,
    createRace,
    listComp,
    compDetail,
    updateComp,
    listLeaderboard,
    prizeTransfer,
    endComp,
    listCampaign,
    bonusCash,
    flexiRollOver,
    listGroup,
    freeBetslip,
    list,
    entryCode,
    setting,
    jackpot,
    sport,
    listBack,
  },
}

export default racesModule
