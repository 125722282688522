/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import '@/services/api'
import '@/services/apiGenWeb'
import i18n from '@/libs/i18n'
import JsonCSV from 'vue-json-csv'
// eslint-disable-next-line import/no-extraneous-dependencies
// import VueApollo from 'vue-apollo'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/vue-google-map'
import '@/libs/vue-recaptcha-v3'
import '@/helpers/filter'

// Axios Mock Adapter
import '@/@fake-db/db'
import apolloProvider from './apollo'
// eslint-disable-next-line import/order
import socket from './services/socket'

const googleAPI = document.createElement('script')
const key = process.env.VUE_APP_GOOGLE_PLACE_API_KEY
googleAPI.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`)
googleAPI.async = true
googleAPI.defer = true

document.head.appendChild(googleAPI)

Vue.component('downloadCsv', JsonCSV)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
// Vue.use(VueApollo)

Vue.prototype.$socket = socket

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')

// document.body.prepend(googleAPI)
