import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  transactionData: null,
  loading: false,
  error: '',
  loadingTransactionList: false,
  loadingRefundTransaction: false,

  totalTrans: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_TRANSACTION: (s, transaction) => (s.transactionData = transaction),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
    SET_LOADING_TRANSACTION_LIST: (s, loadingTransactionList) => (s.loadingTransactionList = loadingTransactionList),
    SET_LOADING_REFUND: (s, loadingRefundTransaction) => (s.loadingRefundTransaction = loadingRefundTransaction),

    SET_TOTAL_TRANSACTION: (s, totalTrans) => (s.totalTrans = totalTrans),
  },

  actions: {
    async asyncTransactionDetail({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/transaction', { params })
        const dataTransaction = res.data.data.trans
        commit('SET_TRANSACTION', dataTransaction)
        return dataTransaction
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async asyncTransactionList({ commit }, params) {
      commit('SET_LOADING_TRANSACTION_LIST', true)
      try {
        const res = await this.$api.get('/portal/transactions', { params })
        const dataTransaction = res.data.data
        commit('SET_TRANSACTION', dataTransaction)
        commit('SET_TOTAL_TRANSACTION', res.data.pagination.total)
        return dataTransaction
      } catch (e) {
        commit('SET_TOTAL_TRANSACTION', 0)
        errorHandler(e)
      } finally {
        commit('SET_LOADING_TRANSACTION_LIST', false)
      }
    },

    async handleRefundTransaction({ commit }, id) {
      commit('SET_LOADING_REFUND', true)
      try {
        const res = await this.$api.post(`/portal/transactions/${id}/refund`)
        const dataTransaction = res.data.data
        return dataTransaction
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('SET_LOADING_REFUND', false)
      }
    },
  },
}
