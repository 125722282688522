import { UserActionTrackingTypeEnum } from '@/@core/utils/enum/user'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: [],
  loading: false,
  error: '',
  totalPage: 0,
})

const dataSet = {
  user: {
    data: [],
    totalPage: 0,
  },
  admin: {
    data: [],
    totalPage: 0,
  },
}

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_DATA: (s, data) => (s.data = data),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => (s.loading = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getHistoryAsync({ commit }, params) {
      commit('LOADING')
      try {
        // const { data: response } = await this.$api.get('/bo/users/history', { params })
        // console.log('response.data', response.data)
        // console.log('response.pagination', response.pagination)

        // const historyList = response.data
        // const { total } = response.pagination
        const total = 3
        const historyList = [
          // {
          //   actionDescription: 'Logout successfully',
          //   actionType: 12,
          //   createdBy: 13503,
          //   createdDate: '2023-08-02T02:29:56.000Z',
          //   fromValue: null,
          //   fullName: 'Test0208',
          //   profileUrl: null,
          //   toValue: null,
          //   userActionTrackingId: 6550,
          //   userId: 13503,
          //   userName: 'Test0208',
          // },
          // {
          //   actionDescription: 'Change userName successfully',
          //   actionType: 11,
          //   createdBy: 13503,
          //   createdDate: '2023-08-02T02:29:32.000Z',
          //   fromValue: null,
          //   fullName: 'Test0208',
          //   profileUrl: null,
          //   toValue: null,
          //   userActionTrackingId: 6549,
          //   userId: 13503,
          //   userName: 'Test0208',
          // },
        ]

        switch (params.type) {
          case UserActionTrackingTypeEnum.User:
            dataSet.user.data = historyList
            dataSet.user.totalPage = total
            break
          case UserActionTrackingTypeEnum.Admin:
            dataSet.admin.data = historyList
            dataSet.admin.totalPage = total
            break
          default:
            break
        }

        commit('SET_DATA', dataSet)
        commit('SET_TOTAL_PAGE', total)
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
