import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,

  error: {},
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),

    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),

    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async updateUserAsync(
      { commit, dispatch },
      {
        id, avatar, body, isAutoFollow, isShowToLoginOnly, coloUserName, coloPassword,
      },
    ) {
      commit('RESET')
      commit('LOADING')
      try {
        if (Object.keys(body).length > 0) {
          await this.$api.put(`/bo/users/v2/profile/${id}`, body)
          dispatch('user/detail/asyncUser', { id }, { root: true })
        }
        if (coloPassword) {
          const payload = { coloPassword, coloUserName }
          if (coloUserName === '') {
            delete payload.coloUserName
          }
          await this.$api.put(`/bo/users/profile/${id}`, payload)
        }

        if (isAutoFollow || isShowToLoginOnly) {
          await this.$api.put(`/bo/users/v2/profile/${id}`, {
            isAutoFollow: JSON.parse(isAutoFollow),
            isShowToLoginOnly: JSON.parse(isShowToLoginOnly),
          })
        }
        if (avatar && avatar.get('image') !== 'null') {
          return await dispatch('updateAvatar', { id, avatar })
        }

        return true
      } catch (e) {
        commit('SET_ERROR', e)
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async updateAvatar({ commit, dispatch }, { id, avatar }) {
      commit('LOADING')
      try {
        const user = await this.$api.put(`/bo/users/v2/profile/${id}`, avatar)
        dispatch('user/detail/asyncUser', { id, loading: false }, { root: true })
        return user
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
