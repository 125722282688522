// import { getTimeStamp } from '@/helpers/getTimeStamp'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: '',
  state: [],
  loading: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_DATA: (s, data) => (s.data = data),
    SET_STATE: (s, state) => (s.state = state),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getNewUserParamsAsync({ commit }) {
      commit('RESET')
      commit('LOADING')
      // const time = getTimeStamp()
      try {
        // const res = await this.$apiGenWeb.get(`/u/NewUserParams?rand=${time}`)
        let res
        if (!res) return

        const state = res.data.st.map(item => ({
          value: item.key,
          label: item.value,
        }))

        commit('SET_DATA', res.data)
        commit('SET_STATE', state)
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
