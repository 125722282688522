import block from './block'
import changePassword from './change-password'
import detail from './detail'
import history from './history'
import list from './list'
import newUserParams from './new-user-params'
import remove from './remove'
import reopenAccount from './reopen-account'
import transaction from './transaction'
import update from './update'
import verify from './verify'
import exportCSV from './export-csv'
import verifyAccount from './verify-account'
import autoFollow from './auto-follow'
import listCard from './list-card'
import logoutAllDevices from './logout-all-devices'

const userModule = {
  namespaced: true,
  modules: {
    list,
    block,
    verify,
    remove,
    detail,
    update,
    history,
    transaction,
    newUserParams,
    changePassword,
    reopenAccount,
    exportCSV,
    verifyAccount,
    autoFollow,
    listCard,
    logoutAllDevices,
  },
}

export default userModule
