import login from './login'
import update from './update'
import changePassword from './change-passwod'
import verify from './verify-recaptcha'
import bonusCash from './bonus-cash'

const accountModule = {
  namespaced: true,
  modules: {
    login, update, changePassword, verify, bonusCash,
  },
}

export default accountModule
