import { errorHandler } from '@/services/toastHandler'

export default {
  namespaced: true,

  state: {
    loading: false,
    error: '',
  },

  mutations: {
    LOADING: s => {
      s.loading = true
      s.error = ''
    },
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async autoFollow({ commit }, payload) {
      commit('LOADING')
      try {
        const { userId } = payload
        const { data: response } = await this.$api.post(`/bo/users/auto-follow/${userId}`)
        return response.data
      } catch (e) {
        const error = e || 'Error auto follow user!'
        errorHandler(error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
