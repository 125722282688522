import Axios from 'axios'
import { camelizeKeys } from 'humps'
import store from '@/store'
import router from '@/router'

const baseURL = process.env.VUE_APP_BASE_URL
const v2URL = process.env.VUE_APP_V2_URL
const fatZebraURL = process.env.VUE_APP_FAT_ZEBRA_URL
const fatToken = process.env.VUE_APP_FAT_ZEBRA_TOKEN

const api = Axios.create({ baseURL })
export const apiFat = Axios.create({ baseURL: fatZebraURL })
export const apiV2 = Axios.create({ baseURL: v2URL })

api.interceptors.request.use(
  config => {
    const { accessToken: token } = store.state.auth
    if (token.expireAt <= new Date().getTime() / 1000) {
      store.dispatch('auth/purgeAuth', null, { root: true })
      router.push({ name: 'auth-login' })
    }
    if (token) config.headers.Authorization = `Bearer ${token.token}`
    return config
  },
  error => Promise.reject(error),
)

api.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
      response.data = camelizeKeys(response.data)
    }
    return response
  },
  error => Promise.reject(error),
)

apiV2.interceptors.request.use(
  config => {
    const { accessToken: token } = store.state.auth
    if (token.expireAt <= new Date().getTime() / 1000) {
      store.dispatch('auth/purgeAuth', null, { root: true })
      router.push({ name: 'auth-login' })
    }
    if (token) config.headers.Authorization = `Bearer ${token.token}`
    return config
  },
  error => Promise.reject(error),
)

apiV2.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
      response.data = camelizeKeys(response.data)
    }
    return response
  },
  error => Promise.reject(error),
)

apiFat.interceptors.request.use(
  config => {
    if (fatToken) config.headers.Authorization = `Bearer ${fatToken}`
    return config
  },
  error => Promise.reject(error),
)

apiFat.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8') {
      response.data = camelizeKeys(response.data)
    }
    return response
  },
  error => Promise.reject(error),
)

store.$api = api

store.$apiFat = apiFat

export default api
