import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
  dataPrizes: {},
  listPrizes: [],
  listFormatFrontPrizes: [],
  listDataFrontPrizes: [],
  listFormatBackPrizes: [],
  listDataBackPrizes: [],
  imageWebUrl: null,
  imageMobileUrl: null,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loading = true),
    SET_LIST_PRIZES: (state, data) => (state.listPrizes = data),
    SET_LIST_FRONT_PRIZES: (state, data) => (state.listFormatFrontPrizes = data),
    SET_DATA_FRONT_PRIZES: (state, data) => (state.listDataFrontPrizes = data),
    SET_LIST_BACK_PRIZES: (state, data) => (state.listFormatBackPrizes = data),
    SET_DATA_BACK_PRIZES: (state, data) => (state.listDataBackPrizes = data),
    SET_DATA_PRIZES: (state, data) => (state.dataPrizes = data),
    SET_IMAGE_WEB_URL: (state, data) => (state.imageWebUrl = data),
    SET_IMAGE_MOBILE_URL: (state, data) => (state.imageMobileUrl = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async createCompAsync({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await this.$api.post('/competitions', payload.data)
        if (res.data.data && payload.data.prizeMode === 'manual') {
          await this.$api.post(`/portal/competitions/${res.data.data.competition.competitionId}/prizes`, { prizes: payload.prizes })
        }
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async uploadImage({ commit }, payload) {
      try {
        const res = await this.$api.post('/portal/images', payload)
        const url = res.data.data.originalUrl
        commit('SET_IMAGE_WEB_URL', url)
        return url
      } catch (e) {
        errorHandler(e)
      }
    },
    async uploadImageMobile({ commit }, payload) {
      try {
        const res = await this.$api.post('/portal/images', payload)
        const url = res.data.data.originalUrl
        commit('SET_IMAGE_MOBILE_URL', url)
        return url
      } catch (e) {
        errorHandler(e)
      }
    },
    setListPrizes: ({ commit }, params) => {
      commit('SET_LIST_PRIZES', params)
    },
    setListFormatFrontPrizes: ({ commit }, params) => {
      commit('SET_LIST_FRONT_PRIZES', params)
    },
    setDataFrontPrizes: ({ commit }, params) => {
      commit('SET_DATA_FRONT_PRIZES', params)
    },
    setListFormatBackPrizes: ({ commit }, params) => {
      commit('SET_LIST_BACK_PRIZES', params)
    },
    setDataBackPrizes: ({ commit }, params) => {
      commit('SET_DATA_BACK_PRIZES', params)
    },

    setDataPrizes: ({ commit }, params) => {
      commit('SET_DATA_PRIZES', params)
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
