import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  loadingStatistic: false,
  error: '',
  dataDetail: {},
  compStatistic: null,
  originEvent: [],
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loading = true),
    SET_DATA: (state, data) => (state.dataDetail = data),
    SET_EVENT: (state, data) => (state.originEvent = data),
    SET_DATA_COMP_STATISTIC: (state, data) => (state.compStatistic = data),
    LOAD_STATISTIC: (state, data) => (state.loadingStatistic = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getCompDetail({ commit }, competitionId) {
      commit('LOADING')
      try {
        const { data } = await this.$api.get(`/portal/competitions/${competitionId}`)
        if (!data.data) return
        commit('SET_DATA', data.data)
        if (data.data.events && data.data.type !== 'sport-tipping') {
          const event = data.data.events.map(i => {
            const newScore = i.scores.map(sc => ({
              ...sc,
              alias: sc.teamAlias,
            }))
            return {
              ...i,
              scores: newScore,
            }
          })
          commit('SET_EVENT', event)
        }

        return data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async getCompStatistic({ commit }, competitionId) {
      commit('LOAD_STATISTIC', true)
      try {
        const { data } = await this.$api.get(`/portal/competitions/${competitionId}/statistic-bet-slip`)
        if (!data.data) {
          const detail = {
            cashBetSlip: 0,
            bonusBetSlip: 0,
            voucherBetSlip: 0,
            mixedBetSlip: 0,
            flexiBetSlip: 0,
            freeBetSlip: 0,
          }
          commit('SET_DATA_COMP_STATISTIC', detail)
          return detail
        }
        const compStatistic = data.data
        commit('SET_DATA_COMP_STATISTIC', compStatistic)
        return compStatistic
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_STATISTIC', false)
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
