/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
import '@/services/api'
import '@/services/apiGenWeb'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-client'
// import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
// import { split } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'

const baseUrlHttp = process.env.VUE_APP_HTTP_LINK_URL
// const baseUrlWss = process.env.VUE_APP_WSS_LINK_URL
const httpLink = new HttpLink({
  uri: baseUrlHttp,
  fetch,
})

// const wsLink = new WebSocketLink({
//   uri: baseUrlWss,
//   options: {
//     reconnect: true,
//     timeout: 30000,
//   },
// })
// const link = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
//   },
//   httpLink,
// )
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    appTypename: true,
  }),
})

const apolloProvider = new VueApollo({
  defaultClient: client,
})
Vue.use(VueApollo)

export default apolloProvider
