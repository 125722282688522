import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  accounts: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_TRENDS: (s, trends) => (s.trends = trends),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/bo/trending', { params })
        const { total } = res.data.pagination
        const trends = res.data.data
        commit('SET_TRENDS', trends)
        commit('SET_TOTAL_PAGE', total)
        return trends
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
