import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingListGroup: false,
  loadingDeleteGroup: false,
  error: '',
  loadingListMember: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD_LIST_GROUP: (s, data) => (s.loadingListGroup = data),
    LOAD_DELETE_GROUP: (s, data) => (s.loadingDeleteGroup = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOAD_LIST_MEMBER: (s, data) => (s.loadingListMember = data),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListGroup({ commit }, params) {
      commit('LOAD_LIST_GROUP', true)
      try {
        const res = await this.$api.get('/portal/groups', { params })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_LIST_GROUP', false)
      }
    },
    async deleteCompGroup({ commit }, params) {
      commit('LOAD_DELETE_GROUP', true)
      try {
        const res = await this.$api.delete('/portal/groups/competitions/remove', {
          params,
        })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_DELETE_GROUP', false)
      }
    },
    async addCompGroup({ commit }, params) {
      commit('LOAD_DELETE_GROUP', true)
      try {
        const res = await this.$api.post('/portal/groups/competitions', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_DELETE_GROUP', false)
      }
    },

    async updateGroupProfile(_, params) {
      try {
        const res = await this.$api.put(`/portal/groups/${params.groupId}`, params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },
    async getListMemberOfGroup({ commit }, params) {
      commit('LOAD_LIST_MEMBER', true)
      try {
        const res = await this.$api.get(`/portal/groups/${params}/members`, {
          params: {
            status: 'joined',
          },
        })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_LIST_MEMBER', false)
      }
    },
  },
}
