import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingListBonus: false,
  loadingCreateBonus: false,
  isCreateSuccess: false,
  error: '',
  listUserByOffer: [],
  totalUser: null,
  bonusId: null,
  loadingGetUser: false,
  createOfferDirect: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingListBonus = true),
    LOADING_CREATE_BONUS: state => (state.loadingCreateBonus = true),
    LOADING_BONUS: state => (state.loadingGetUser = true),
    SET_ERROR: (s, error) => (s.error = error),
    SET_DATA: (s, listUserByOffer) => (s.listUserByOffer = listUserByOffer),
    SET_TOTAL: (s, totalUser) => (s.totalUser = totalUser),
    SET_BONUS_ID: (s, id) => (s.bonusId = id),
    LOADED: s => (s.loadingListBonus = false),
    LOADED_CREATE_BONUS: s => (s.loadingCreateBonus = false),
    LOADED_BONUS: s => (s.loadingGetUser = false),
    LOAD_CREATE_BONUS_DIRECT: (s, data) => (s.createOfferDirect = data),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListBonus({ commit }, params) {
      commit('LOADING')
      try {
        const res = await this.$api.get('/bonus-offers', { params })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async createBonus({ commit }, payload) {
      commit('LOADING_CREATE_BONUS')
      try {
        const res = await this.$api.post('/portal/bonus-offers', payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_BONUS')
      }
    },
    async addProfileUser({ commit }, payload) {
      commit('LOADING_CREATE_BONUS')
      const { bonusOfferId, data } = payload
      try {
        const res = await this.$api.post(`portal/bonus-offers/${bonusOfferId}/applies`, {
          applies: data,
        })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_BONUS')
      }
    },
    async removeProfileUser({ commit }, payload) {
      commit('LOADING_CREATE_BONUS')
      const { bonusOfferId, data } = payload
      try {
        const res = await this.$api.post(`portal/bonus-offers/${bonusOfferId}/applies/delete`, {
          applies: data,
        })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_BONUS')
      }
    },
    async updateOffer({ commit }, payload) {
      commit('LOADING_CREATE_BONUS')
      const { bonusOfferId, data } = payload
      try {
        const res = await this.$api.put(`portal/bonus-offers/${bonusOfferId}`, {
          ...data,
        })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_BONUS')
      }
    },
    async deleteOffer({ commit }, payload) {
      commit('LOADING_CREATE_BONUS')
      try {
        const res = await this.$api.delete(`portal/bonus-offers/${payload}`)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_BONUS')
      }
    },
    async getOffer({ commit }, payload) {
      commit('LOADING_BONUS')
      try {
        const { bonusOfferId, data } = payload
        const res = await this.$api.get(`portal/bonus-offers/${bonusOfferId}/applies`, {
          params: data,
        })
        commit('SET_DATA', res.data.data)
        commit('SET_TOTAL', res.data.pagination.total)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_BONUS')
      }
    },
    setBonusOfferId({ commit }, payload) {
      commit('SET_BONUS_ID', payload)
    },
    async createOfferDirect({ commit }, payload) {
      commit('LOAD_CREATE_BONUS_DIRECT', true)
      const { bonusOfferId, data, amount } = payload
      try {
        const res = await this.$api.post(`portal/bonus-offers/${bonusOfferId}/bonus/add-bulk`, {
          userIds: data,
          amount,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CREATE_BONUS_DIRECT', false)
      }
    },
    async getUserBonusOfferDirect({ commit }, payload) {
      commit('LOADING_BONUS')
      try {
        const { bonusOfferId, data } = payload
        const res = await this.$api.get(`/portal/bonus-offers/${bonusOfferId}/user-bonus`, {
          params: data,
        })
        commit('SET_DATA', res.data.data)
        commit('SET_TOTAL', res.data.pagination.total)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_BONUS')
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
  },
}
