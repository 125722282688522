<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <UserDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('races/listRace', ['tokenBetMaket', 'expireAt']),
    ...mapState('auth', ['user']),
  },

  created() {
    if (!this.tokenBetMaket && this.user) {
      this.getTokenBetMaker()
    }
  },
  methods: {
    ...mapActions({
      getTokenBetMaker: 'races/listRace/getTokenBetMaker',
    }),
  },
}
</script>
