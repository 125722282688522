import { errorHandler } from '@/services/toastHandler'

export default {
  state: {
    loading: false,
    error: '',
    success: false,
  },

  mutations: {
    LOADING: s => {
      s.loading = true
      s.error = ''
    },
    SET_ERROR: (s, error) => (s.error = error),
    SUCCESSED: s => (s.success = true),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async changeSport({ commit }, payload) {
      commit('LOADING')
      try {
        const { userId, sportId } = payload
        await this.$api.put('bo/sports', {
          userId,
          sportId,
        })
        commit('SUCCESSED')
      } catch (e) {
        const error = e || 'Error change sport!'
        errorHandler(error)
        commit('SET_ERROR', error)
      } finally {
        commit('LOADED')
      }
    },
  },
}
