import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
  listPastJackpot: [],
  totalPastJackpot: null,
  loadingApprove: false,
  jackpotId: null,
  totalCurrentJackpot: null,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOADING: s => (s.loading = true),
    SET_LIST_PAST_JACKPOT: (s, data) => (s.listPastJackpot = data),
    SET_TOTAL_PAST_JACKPOT: (s, data) => (s.totalPastJackpot = data),
    LOAD_APPROVE_PRIZE: (s, data) => (s.loadingApprove = data),
    SET_JACKPOT_ID: (s, data) => (s.jackpotId = data),
    SET_TOTAL_CURRENT_JACKPOT: (s, data) => (s.totalCurrentJackpot = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListPastJackpot({ commit }, {
      page, limit,
    }) {
      commit('LOADING')
      try {
        const params = {
          limit,
          page,
          isNotPending: true,
        }
        const res = await this.$api.get('/jackpot-leaderboards', { params })
        const data = res.data.data.items
        const { total } = res.data.pagination
        commit('SET_LIST_PAST_JACKPOT', data)
        commit('SET_TOTAL_PAST_JACKPOT', total)

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async setPrizeTransfer({ commit }, payload) {
      const { jackpotId } = payload
      commit('LOAD_APPROVE_PRIZE', true)
      try {
        await this.$api.post(`/portal/jackpots/${jackpotId}/approve`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_APPROVE_PRIZE', false)
      }
    },
    async getLatestJackpot({ commit }) {
      try {
        const res = await this.$api.get('/jackpots/')
        commit('SET_JACKPOT_ID', res.data.data.jackpotId)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        console.log('err')
      }
    },
    async getListCurrentJackpot({ commit }, {
      page, limit,
    }) {
      commit('LOADING')
      try {
        const params = {
          limit,
          page,
          status: 'pending',
          // hasPrize: true,
        }
        const res = await this.$api.get('/jackpot-leaderboards', { params })
        const data = res.data.data.items
        const { total } = res.data.pagination
        commit('SET_TOTAL_CURRENT_JACKPOT', total)

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
  },
}
