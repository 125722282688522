export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/users/create-account',
    name: 'users-create-account',
    component: () => import('@/views/apps/user/create-account/CreateAccount.vue'),
    meta: {
      pageTitle: 'Create User',
      breadcrumb: [
        {
          to: { name: 'users-list' },
          text: 'List Users',
        },
        {
          text: 'Create User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      pageTitle: 'User Details',
      breadcrumb: [
        {
          to: { name: 'users-list' },
          text: 'List Users',
        },
        {
          text: 'User Details',
          active: true,
        },
      ],
    },
  },

  // *--------- RACING LIST ---- ---------------------------------------*
  // {
  //   path: '/racing/list',
  //   name: 'racing-list',
  //   component: () => import('@/views/apps/racing/racing-list/RacingList.vue'),
  // },
  // {
  //   path: '/racing/event/:id',
  //   name: 'racing-list-event',
  //   component: () => import('@/views/apps/racing/racing-event/EventList.vue'),
  //   meta: {
  //     pageTitle: 'Racing Details',
  //     breadcrumb: [
  //       {
  //         to: { name: 'racing-list' },
  //         text: 'List Racing',
  //       },
  //       {
  //         text: 'Racing Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/racing/event/:id/detail/:id',
  //   name: 'racing-list-event-detail',
  //   component: () => import('@/views/apps/racing/racing-event/EventDetail.vue'),
  //   meta: {
  //     pageTitle: 'Racing Details',
  //     breadcrumb: [
  //       {
  //         to: { name: 'racing-list' },
  //         text: 'List Racing',
  //       },
  //       {
  //         to: { name: 'racing-list-event' },
  //         text: 'Racing Details',
  //       },
  //       {
  //         text: 'Event Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // *--------- COMP LIST ---- ---------------------------------------*
  {
    path: '/comp/list',
    name: 'comp-list',
    component: () => import('@/views/apps/comp/comp-list/CompList.vue'),
  },
  {
    path: '/comp/view/:id',
    name: 'comp-view',
    component: () => import('@/views/apps/comp/comp-detail/CompView.vue'),
    meta: {
      pageTitle: 'Comp Details',
      breadcrumb: [
        {
          to: { name: 'comp-list' },
          text: 'List Comps',
        },
        {
          text: 'Comp Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/comp/update-comp/:id',
    name: 'comp-update',
    component: () => import('@/views/apps/comp/comp-detail/UpdateCompView.vue'),
  },

  // *--------- CREATE COMP ---- ---------------------------------------*
  {
    path: '/comp/create-comp',
    name: 'comp-create',
    component: () => import('@/views/apps/comp/create-comp/CreateComp.vue'),
  },
  // *--------- CREATE SPORT COMP ---- ---------------------------------------*
  {
    path: '/comp/create-sport-comp',
    name: 'sport-comp-create',
    component: () => import('@/views/apps/comp-sport/create-sport-comp/CreateComp.vue'),
  },
  // *--------- CREATE TIPPING COMP ---- ---------------------------------------*
  {
    path: '/comp/create-tipping-comp',
    name: 'tipping-comp-create',
    component: () => import('@/views/apps/tipping-comp/CreateComp.vue'),
  },
  {
    path: '/update-tipping/:id',
    name: 'tipping-update',
    component: () => import('@/views/apps//tipping-comp/TippingListTab.vue'),
    meta: {
      pageTitle: 'Tipping Details',
      breadcrumb: [
        {
          to: { name: 'comp-list' },
          text: 'List Comps',
        },
        {
          text: 'Tipping Details',
          active: true,
        },
      ],
    },
  },

  // *--------- COMP SETTINGS ---- ---------------------------------------*
  {
    path: '/comp/settings',
    name: 'setting',
    component: () => import('@/views/apps/comp/settings/SettingView.vue'),
  },

  // *--------- COMP JACKPOT ---- ---------------------------------------*
  {
    path: '/comp/jackpot',
    name: 'jackpot',
    component: () => import('@/views/apps/jackpot/JackpotView.vue'),
  },
  // *--------- SPORT ---- ---------------------------------------*
  {
    path: '/comp/sport',
    name: 'sport',
    component: () => import('@/views/apps/comp-sport/list-sport/ListSport.vue'),
  },
  // *--------- TIPPING ---- ---------------------------------------*
  {
    path: '/comp/tipping',
    name: 'tipping',
    component: () => import('@/views/apps/comp-sport/list-sport/ListSportTipping.vue'),
  },

  // *--------- WITHDRAWAL LIST ---- ---------------------------------------*
  {
    path: '/withdraw',
    name: 'withdraw-list',
    component: () => import('@/views/apps/transaction/transaction-list/WithdrawTab.vue'),
  },
  // *--------- WITHDRAWAL UPCOZ LIST ---- ---------------------------------------*
  {
    path: '/upcoz-list',
    name: 'upcoz-list',
    component: () => import('@/views/apps/transaction/transaction-list/upcoz/WithdrawTab.vue'),
  },

  // *--------- Deposit LIST ---- ---------------------------------------*
  {
    path: '/deposit',
    name: 'deposit-list',
    component: () => import('@/views/apps/transaction/transaction-list/DepositList.vue'),
  },
  // *--------- All trans ---- ---------------------------------------*
  {
    path: '/transaction',
    name: 'transaction-list',
    component: () => import('@/views/apps/transaction/all-transactions/AllTransactions.vue'),
  },

  // *--------- logging ---- ---------------------------------------*
  {
    path: '/logging',
    name: 'logging-list',
    component: () => import('@/views/apps/transaction/logging/AllTransactions.vue'),
  },

  {
    path: '/notification',
    name: 'push-notification',
    component: () => import('@/views/apps/notification/Notification.vue'),
  },

  {
    path: '/group-notification',
    name: 'group-notification',
    component: () => import('@/views/apps/notification/GroupNotification.vue'),
  },
  // *--------- YNYM ---- ---------------------------------------*
  {
    path: '/ynym',
    name: 'ynym',
    component: () => import('@/views/apps/ynym/ListGroup.vue'),
  },

  {
    path: '/notification-view',
    name: 'notification-view',
    component: () => import('@/views/apps/notification/NotificationListTab.vue'),
  },

]
