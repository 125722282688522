import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  error: '',
  loadingCreate: false,
  loadingPrize: false,
  loadingDeletePrize: false,
  loadingListEvent: false,
  loadingUpdateRealEvent: false,
  loadingGetMarket: false,
  loadingUpdateOdd: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD_CREATE: (state, data) => (state.loadingCreate = data),
    LOAD_PRIZE: (state, data) => (state.loadingPrize = data),
    LOAD_DELETE_PRIZE: (state, data) => (state.loadingDeletePrize = data),
    LOAD_LIST_EVENT: (state, data) => (state.loadingListEvent = data),
    LOAD_UPDATE_REAL_EVENT: (state, data) => (state.loadingUpdateRealEvent = data),
    LOAD_MARKET_EVENT: (state, data) => (state.loadingGetMarket = data),
    LOAD_UPDATE_ODD: (state, data) => (state.loadingUpdateOdd = data),
  },

  actions: {
    async createTippingComp({ commit }, params) {
      commit('LOAD_CREATE', true)
      try {
        const res = await this.$api.post('/portal/competitions/type/sport-tipping', params)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CREATE', false)
      }
    },
    async createNewTippingPrize({ commit }, params) {
      commit('LOAD_PRIZE', true)

      const { competitionId, data } = params
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/tipping-prizes`, data)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_PRIZE', false)
      }
    },
    async deleteTippingPrize({ commit }, params) {
      commit('LOAD_DELETE_PRIZE', true)

      const { competitionId, data } = params
      try {
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/tipping-prizes`, {
          params: data,
        })

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_DELETE_PRIZE', false)
      }
    },
    async getListEventTipping({ commit }, params) {
      commit('LOAD_LIST_EVENT', true)

      const { competitionId, fromWeek, toWeek } = params
      try {
        const res = await this.$api.get(`/competitions/${competitionId}/sport-events`, {
          params: {
            fromWeek,
            toWeek,
          },
        })

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_LIST_EVENT', false)
      }
    },
    async updateRealEvent({ commit }, params) {
      commit('LOAD_UPDATE_REAL_EVENT', true)

      const { competitionId, data } = params
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/replace-event`, data)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_UPDATE_REAL_EVENT', false)
      }
    },
    async getMarketOfEvent({ commit }, params) {
      commit('LOAD_MARKET_EVENT', true)

      try {
        const res = await this.$api.get(`/portal/sports/events/${params}/market?limit=1`)
        if (res.data.data) return res.data.data[0]
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_MARKET_EVENT', false)
      }
    },
    async updateNewOdds({ commit }, params) {
      commit('LOAD_UPDATE_ODD', true)

      try {
        const res = await this.$api.put('/portal/sports/market-selections', params)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_UPDATE_ODD', false)
      }
    },
  },
}
