import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingTransfer: false,
  loadingTransferFront: false,
  loadingTransferBack: false,
  loadingTop10: false,
  loadingExceptTop10: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingTransfer = true),
    LOADING_TOP_10: state => (state.loadingTop10 = true),
    LOADING_EXCEPT_TOP_10: state => (state.loadingExceptTop10 = true),

    LOADING_TRANSFER_FRONT: state => (state.loadingTransferFront = true),
    LOADING_TRANSFER_BACK: state => (state.loadingTransferBack = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loadingTransfer = false),
    LOADED_TRANSFER_FRONT: s => (s.loadingTransferFront = false),
    LOADED_TRANSFER_BACK: s => (s.loadingTransferBack = false),
    LOADED_TOP_10: state => (state.loadingTop10 = false),
    LOADED_EXCEPT_TOP_10: state => (state.loadingExceptTop10 = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async setPrizeTransfer({ commit }, payload) {
      const { competitionId } = payload
      commit('LOADING')
      try {
        await this.$api.post(`/portal/race-results/competition/${competitionId}/prize-transfer`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async setPrizeTransferForTop10({ commit }, payload) {
      const { competitionId } = payload
      commit('LOADING_TOP_10')
      try {
        await this.$api.post(`/portal/competitions/${competitionId}/prizes/approve-top-10`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_TOP_10')
      }
    },

    async setPrizeTransferExceptTop10({ commit }, payload) {
      const { competitionId } = payload
      commit('LOADING_EXCEPT_TOP_10')
      try {
        await this.$api.post(`/portal/competitions/${competitionId}/prizes/approve-except-top-10`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_EXCEPT_TOP_10')
      }
    },
    async setPrizeTransferFront({ commit }, payload) {
      commit('LOADING_TRANSFER_FRONT')
      try {
        await this.$api.post('/portal/entry-leaderboard/prize-transfer', payload)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_TRANSFER_FRONT')
      }
    },
    async setPrizeTransferBack({ commit }, payload) {
      commit('LOADING_TRANSFER_BACK')
      try {
        await this.$api.post('/portal/entry-leaderboard/prize-transfer', payload)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_TRANSFER_BACK')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
