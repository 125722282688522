// import { formatRole } from '@/helpers/permission'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  leagues: [],
  loading: false,
  error: '',
  loadingUpdate: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_LEAGUES: (s, leagues) => (s.leagues = leagues),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_LOADING_UPDATE_LEAGUE: (s, data) => (s.loadingUpdate = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
  },

  actions: {
    async getListLeagues({ commit }) {
      commit('LOADING')
      try {
        const res = await this.$api.get('/portal/sports/leagues')
        if (res.data && Object.keys(res.data).length) {
          commit('SET_LEAGUES', res.data.data)
          return res.data.data
        }
        return null
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async handleUpdateLeague({ commit }, params) {
      commit('SET_LOADING_UPDATE_LEAGUE', true)
      try {
        const { sportLeagueId, ...rest } = params
        await this.$api.put(`/portal/sports/leagues/${sportLeagueId}`, {
          ...rest,
        })
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('SET_LOADING_UPDATE_LEAGUE', false)
      }
    },
    async handleDeleteLeague({ commit }, params) {
      commit('SET_LOADING_UPDATE_LEAGUE', true)
      try {
        await this.$api.delete(`/portal/sports/leagues/${params}`)

        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('SET_LOADING_UPDATE_LEAGUE', false)
      }
    },
    async handleUpdateOrdernumberLeagues({ commit }, params) {
      commit('SET_LOADING_UPDATE_LEAGUE', true)
      try {
        await this.$api.put('/portal/sports/leagues/order-number', {
          ...params,
        })

        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('SET_LOADING_UPDATE_LEAGUE', false)
      }
    },

    async handleAddNewLeague({ commit }, params) {
      commit('SET_LOADING_UPDATE_LEAGUE', true)
      try {
        await this.$api.post('/portal/sports/leagues', {
          ...params,
        })

        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('SET_LOADING_UPDATE_LEAGUE', false)
      }
    },
  },
}
