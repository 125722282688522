import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  transactions: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_TRANSACTIONS: (s, transactions) => (s.transactions = transactions),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async asyncTransaction({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/transaction', { params })
        const { total } = res.data.pagination
        const dataTransactions = res.data.data.trans
        commit('SET_TRANSACTIONS', dataTransactions)
        commit('SET_TOTAL_PAGE', total)
        return dataTransactions
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
