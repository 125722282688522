import { errorHandler } from '@/services/toastHandler'
import { STATUS_PAYMENT } from '@/views/apps/comps-management/comp-detail/useParticipant'

const initState = () => ({
  loading: false,
  error: {},
  success: false,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    SUCCESSED: s => (s.success = true),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async do({ commit, dispatch }, body) {
      commit('RESET')
      commit('LOADING')
      try {
        if (body.status === STATUS_PAYMENT.ELIMINATE) {
          const result = await dispatch('competition/deleteParticipant/do', body, { root: true })
          return result
        }
        const response = await this.$api.post('/bo/sdp-comps/comp/payment', body)
        if (!response.data) return
        return response.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
