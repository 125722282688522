import { errorHandler } from '@/services/toastHandler'
import { apiV2 } from '@/services/api'

const initState = () => ({
  userData: null,
  loading: false,
  loadingAddBalance: false,
  loadingListVouchers: false,
  loadingCreateAccount: false,
  loadingClientProfile: false,
  loadingAddCoupon: false,
  loadingWithdrawable: false,
  loadingListToken: false,
  error: '',
  totalToken: 0,
  totalVouchers: 0,
  tokenList: [],
  loadingCheckBetstop: false,
  loadingTransferUPCOZ: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER: (s, user) => (s.userData = user),
    SET_USER_PROFILE: (s, profile) => (s.userData.profile = profile),
    LOADING: s => (s.loading = true),
    DELETE_BANK_ACCOUNT: (s, id) => (s.userData.bankAccounts = s.userData.bankAccounts.filter(b => b.bankAccountId !== id)),
    LOADING_ADD_BALANCE: s => (s.loadingAddBalance = true),
    LOADED_ADD_BALANCE: s => (s.loadingAddBalance = false),
    LOADING_CLIENT_PROFILE: s => (s.loadingClientProfile = true),
    LOADING_WITHDRAWABLE: (s, loading) => (s.loadingClientProfile = loading),
    LOADED_CLIENT_PROFILE: s => (s.loadingClientProfile = false),
    LOADING_CREATE_ACCOUNT: s => (s.loadingCreateAccount = true),
    LOADED_CREATE_ACCOUNT: s => (s.loadingCreateAccount = false),
    LOADING_ADD_COUPON: s => (s.loadingAddCoupon = true),
    LOADING_LIST_VOUCHER: (s, loading) => (s.loadingListVouchers = loading),

    LOADED_ADD_COUPON: s => (s.loadingAddCoupon = false),
    SET_TOTAL_TOKEN: (s, total) => (s.totalToken = total),
    SET_TOTAL_VOUCHER: (s, total) => (s.totalVouchers = total),

    SET_LIST_TOKEN: (s, list) => (s.tokenList = [...list]),
    LOADING_LIST_TOKEN: s => (s.loadingListToken = true),
    LOADED_LIST_TOKEN: s => (s.loadingListToken = false),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    LOAD_CHECK_BETSTOP: (s, data) => (s.loadingCheckBetstop = data),
    LOAD_TRANSFER: (s, data) => (s.loadingTransferUPCOZ = data),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async asyncUser({ commit }, param) {
      commit('LOADING')
      try {
        const res = await this.$api.get(`portal/users/${param.id}`)
        if (res.data && Object.keys(res.data.data).length) {
          commit('SET_USER', res.data.data)
          return res.data.data
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async updateUserBalance({ commit }, param) {
      commit('LOADING_ADD_BALANCE')

      try {
        const res = await this.$api.put(`/wallet/user/${param.id}/balance`, {
          amount: Number(param.amount),
        })
        if (res.data && Object.keys(res.data.data).length) {
          return true
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_BALANCE')
      }
    },
    async updateUserWithdrawal({ commit }, param) {
      commit('LOADING_WITHDRAWABLE', true)

      try {
        const res = await this.$api.put(`/wallet/user/${param.id}/maximum-withdrawal-value`, {
          amount: Number(param.amount),
        })
        if (res.data && Object.keys(res.data.data).length) {
          return true
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADING_WITHDRAWABLE', false)
      }
    },
    async updateClientProfile({ commit }, param) {
      commit('LOADING_CLIENT_PROFILE')

      try {
        const res = await this.$api.put(`/portal/users/${param.userId}`, {
          ...param,
        })
        if (res.data && Object.keys(res.data.data).length) {
          // commit('SET_USER_PROFILE', res.data.data?.profile?.replace('_', ' '))
          return true
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CLIENT_PROFILE')
      }
    },
    async createAccount({ commit }, param) {
      commit('LOADING_CREATE_ACCOUNT')

      try {
        const res = await this.$api.post('/portal/users', {
          ...param,
        })
        if (res.data && Object.keys(res.data.data).length) {
          return true
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_ACCOUNT')
      }
    },
    async handleAddCoupon({ commit }, param) {
      commit('LOADING_ADD_COUPON')

      try {
        const res = await this.$api.post('/portal/coupons/rebuy-token', {
          userIds: param.id ? [param.id] : [],
          amount: param.amount,
          userProfiles: param.profile ? [param.profile] : [],
          expiredTime: param.expiredTime,
          couponType: param.couponType,
        })
        if (res.data && Object.keys(res.data.data).length) {
          return true
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_COUPON')
      }
    },
    async getListReBuy({ commit }, param) {
      commit('LOADING_LIST_TOKEN')
      try {
        const res = await this.$api.get('/portal/coupons', {
          params: {
            userId: param.id,
            limit: param.limit,
            page: param.page ? param.page : 1,
            shouldExpired: param.shouldExpired,
            couponStatus: param.couponStatus,
            couponType: param.couponType,
            onlyCount: param.onlyCount || 0,
          },
        })
        if (res.data && Object.keys(res.data.data).length) {
          commit('SET_TOTAL_TOKEN', res.data.data.pagination.total)
          commit('SET_LIST_TOKEN', res.data.data.coupons)

          return res.data.data
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_LIST_TOKEN')
      }
    },
    async getListVouchers({ commit }, param) {
      commit('LOADING_LIST_VOUCHER')
      try {
        const res = await this.$api.get('/portal/coupons', {
          params: {
            userId: param.id,
            limit: param.limit,
            page: param.page ? param.page : 1,
            shouldExpired: param.shouldExpired,
            couponStatus: param.couponStatus,
            couponType: param.couponType,
            onlyCount: param.onlyCount || 0,
          },
        })
        if (res.data && Object.keys(res.data.data).length) {
          commit('SET_TOTAL_VOUCHER', res.data.data.pagination.total)

          return res.data.data
        }
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADING_LIST_VOUCHER')
      }
    },

    async handleCheckBetstop({ commit }, params) {
      commit('LOAD_CHECK_BETSTOP', true)

      try {
        const res = await this.$api.post('/portal/bet-stop', {
          firstName: params.firstName,
          lastName: params.lastName,
          dob: params.dob,
          email: params.email,
          phoneNumber: params.phoneNumber,
          postCode: params.postCode,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHECK_BETSTOP', false)
      }
    },

    async transferUPCOZWallet({ commit }, params) {
      commit('LOAD_TRANSFER', true)
      try {
        const res = await this.$api.post('/portal/wallet/transfer', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_TRANSFER', false)
      }
    },
    async withdrawUPCOZWallet({ commit }, params) {
      commit('LOAD_TRANSFER', true)
      try {
        const res = await apiV2.post('/v2/portal/withdrawal-requests', params)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_TRANSFER', false)
      }
    },
  },
}
