import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loading = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async createSportComp({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await this.$api.post('/portal/competitions/type/sport', payload.data)
        if (res.data.data && payload.data.prizeMode === 'manual') {
          await this.$api.post(`/portal/competitions/${res.data.data.competitionId}/prizes`, { prizes: payload.prizes })
        }
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async addLeagueToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, sportLeagueId } = payload
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/leagues`, {
          sportLeagueId,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async addSportEventsToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, events } = payload
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/sport-events`, {
          events,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async addSportMarketsToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, eventId, markets } = payload
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets`, {
          markets,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    // eslint-disable-next-line no-empty-pattern
    async getListMarketOfSportComp({ }, payload) {
      const { competitionId, eventId } = payload
      try {
        const res = await this.$api.get(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },

    // eslint-disable-next-line no-empty-pattern
    async uploadFlag({ }, payload) {
      try {
        const res = await this.$api.post('/portal/images', payload)
        const url = res.data.data.originalUrl
        return url
      } catch (e) {
        errorHandler(e)
      }
    },

    async removeAllEvent(_, payload) {
      try {
        const res = await this.$api.delete(`/portal/competitions/${payload}/sport-events`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
