import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  flexiRollOverWallet: 0,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loading = true),
    SET_DATA: (state, data) => (state.flexiRollOverWallet = data),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getFlexiRollOverWallet({ commit }) {
      commit('LOADING')
      try {
        const res = await this.$api.get('/flexi-roll-over-wallets/prize')
        if (res.data.data) {
          commit('SET_DATA', res.data.data?.balance)
          return res.data.data
        }
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
