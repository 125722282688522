import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  listComp: [],
  listFeatureComp: [],
  loading: false,
  loadingSetFeature: false,
  loadingDeleteComp: false,
  loadingSortFeature: false,
  error: '',
  total: 0,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOADING: s => (s.loading = true),
    SET_TOTAL_PAGE: (s, total) => (s.total = total),
    LOADING_SET_FEATURE: s => (s.loadingSetFeature = true),
    LOADING_DELETE_COMP: s => (s.loadingDeleteComp = true),
    SET_DATA_LIST_COMP: (s, data) => (s.listComp = data),
    SET_DATA_LIST_FEATURE_COMP: (s, data) => (s.listFeatureComp = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    LOADED_DELETE_COMP: s => (s.loadingDeleteComp = false),
    LOADED_SET_FEATURE: s => (s.loadingSetFeature = false),
    LOAD_SORT: (s, data) => (s.loadingSortFeature = data),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListComp({ commit }, {
      page, limit, createdTimeFrom, createdTimeTo, sortValue, sortFIeld, type, sportLeagueId,
    }) {
      commit('LOADING')
      try {
        const params = {
          limit,
          page,
          ...(sortFIeld && { sortFIeld }),
          ...(sortValue && { sortValue: sortValue.toUpperCase() }),
          createdTimeFrom,
          createdTimeTo,
          type,
          sportLeagueId,
        }
        const res = await this.$api.get('/portal/competitions', { params })
        const compData = res.data.data
        const { total } = res.data.pagination
        commit('SET_DATA_LIST_COMP', compData)
        commit('SET_TOTAL_PAGE', total)
        return compData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async getListFeatureComp({ commit }, {
      featured, page, limit, withDetailFeatured,
    }) {
      try {
        const params = {
          limit,
          page,
          featured,
          withDetailFeatured,
          endDateFrom: new Date().toISOString(),
          sortField: 'orderFeaturedNumber',
          sortValue: 'DESC',
        }
        const res = await this.$api.get('/portal/competitions', { params })
        const compData = res.data.data
        commit('SET_DATA_LIST_FEATURE_COMP', compData)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        // commit('LOADED')
      }
    },

    async setFeatureComp({ commit }, competitionId) {
      commit('LOADING_SET_FEATURE')
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/featured`, {
          featured: 1,
        })
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_SET_FEATURE')
      }
    },

    async unSetFeatureComp({ commit }, competitionId) {
      commit('LOADING_SET_FEATURE')
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/featured`, {
          featured: 0,
        })
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_SET_FEATURE')
      }
    },

    async deleteComp({ commit }, competitionId) {
      commit('LOADING_DELETE_COMP')
      try {
        const res = await this.$api.delete(`/portal/competitions/${competitionId}`)
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_DELETE_COMP')
      }
    },
    async updateSortListFeatureComp({ commit }, competitionIds) {
      commit('LOAD_SORT', true)
      try {
        const res = await this.$api.put('/portal/competitions/featured/order-featured-number', competitionIds)
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SORT', false)
      }
    },
    async getListCompPosition({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await this.$api.get('/competitions/general/list', { params: payload })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async updateSortListComp({ commit }, competitionIds) {
      commit('LOAD_SORT', true)
      try {
        const res = await this.$api.put('/portal/competitions/order-number/bulk', competitionIds)
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SORT', false)
      }
    },
    async copyComp({ commit }, competitionId) {
      commit('LOADING_DELETE_COMP')
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/duplicate`)
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_DELETE_COMP')
      }
    },
    async publicComp({ commit }, payload) {
      const {
        competitionId, status,
      } = payload
      commit('LOADING_DELETE_COMP')
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/status`, {
          status,
        })
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_DELETE_COMP')
      }
    },
    async setPublicCompSchedual({ commit }, payload) {
      const {
        competitionId, publishDate,
      } = payload
      commit('LOADING_DELETE_COMP')
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/publish-date`, {
          publishDate,
        })
        if (!res.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_DELETE_COMP')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
