import create from './create'
import league from './league'
import payout from './payout'

const tippingModule = {
  namespaced: true,
  modules: {
    create,
    payout,
    league,
  },
}

export default tippingModule
