const UserRoleEnum = {
  SuperAdmin: 1,
  Admin: 2,
  User: 3,
}

const UserStatusEnum = {
  unblock: 0,
  blocked: 1,
}
class UserEnum {
  static get UserRoleEnum() {
    return UserRoleEnum
  }

  static get UserStatusEnum() {
    return UserStatusEnum
  }
}
export default UserEnum

export const UserClosed = {
  normal: 0,
  closedTime: 1,
  closedTerminal: 2,
}

export const UserAction = {
  VERIFY: 'verify',
  BLOCK: 'block',
  UNBLOCK: 'unblock',

  REOPEN: 'reopen',
  DELETE: 'delete',
  VERIFY_ACCOUNT: 'verifyAccount',
  ADD_BALANCE: 'addBalance',
  ADD_RE_BUY_TOKEN: 'addReBuyToken',
  ADD_VOUCHER: 'addVoucher',

  SET_CLIENT_PROFILE: 'setClientProfile',
  UPDATE_AFFILIATE: 'affiliateUpdate',
  UPDATE_SUB_AFFILIATE: 'subAffiliateUpdate',

  UPDATE_WITHDRAWABLE: 'updateWithdrawable',
  UPDATE_NOTES: 'updateNotes',
  DELETE_BANK_ACCOUNT: 'deleteBankAccount',

  DELETE_CREDIT_CARD: 'deleteCreditCard',
  ADD_UPCBALANCE: 'transferUPCOZBallance',
  WITHDRAW_UPCBALANCE: 'withdrawUPCOZWallet',
}

export const UserActionTrackingTypeEnum = {
  Admin: 1,
  User: 3,
}

export const ExportCSVEnum = {
  All: 1,
  Time: 2,
}

export const ADMIN_UPCOZ = process.env.VUE_APP_UPCOZ_ACC
