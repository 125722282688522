import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: '',
  loading: false,
  error: '',
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    SET_SEASON: (s, data) => (s.data = data),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/bo/sdp-comps/season/detail', { params })
        const seasonData = res.data.data
        if (!seasonData) return
        if (seasonData.round) {
          seasonData.round.forEach(round => {
            round.match.forEach(match => {
              const teamA = seasonData.team.find(team => team.teamId === match.teamAId)
              const teamB = seasonData.team.find(team => team.teamId === match.teamBId)
              match.teamA = { ...teamA }
              match.teamB = { ...teamB }
            })
          })
        }
        commit('SET_SEASON', seasonData)
        return seasonData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
