// eslint-disable-next-line import/no-extraneous-dependencies
import io from 'socket.io-client'

const socketURL = process.env.VUE_APP_SOCKET_URL
const token = process.env.VUE_APP_SOCKET_TOKEN

const socket = io(socketURL, {
  transports: ['websocket'],
  auth: {
    'socket-token': token,
  },
})

export default socket
