import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: {},
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async setLogoutAllDevices({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        await this.$api.post(`/portal/users/${payload}/logout-from-all-devices`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
