// import { convertSportCode } from '@/helpers/utils'
import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  listEvent: null,
  loading: false,
  loadingListSelectedEvent: false,
  loadingAdd: false,
  loadingLeague: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_DATA: (s, listEvent) => (s.listEvent = listEvent),
    LOAD: (state, data) => (state.loading = data),
    LOAD_SELECTED_EVENT: (state, data) => (state.loadingListSelectedEvent = data),
    LOAD_ADD_EVENT: (state, data) => (state.loadingAdd = data),
    LOAD_LEAGUES: (state, data) => (state.loadingLeague = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListEvent({ commit }, payload) {
      try {
        const res = await this.$api.get(`/portal/sports/${payload.sportCode}/events?days=30&league=${payload.value}`)
        const data = res.data.data.events
        commit('SET_DATA', data)

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async getListSelectedEvent({ commit }, payload) {
      commit('LOAD_SELECTED_EVENT', true)
      try {
        // eslint-disable-next-line no-shadow
        const params = {
          page: 1,
          limit: 100,
          isPublish: 1,
          ...payload,
        }
        const res = await this.$api.get('/sports/events', { params })
        const data = res.data

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SELECTED_EVENT', false)
      }
    },

    async getListAllEvent({ commit }, payload) {
      commit('LOAD_SELECTED_EVENT', true)
      try {
        // eslint-disable-next-line no-shadow
        const params = {
          page: 1,
          limit: 100,
          isPublish: 0,
          startTimeFrom: payload.time,
          startTimeTo: payload.endTime,
          league: payload.league,
        }
        const res = await this.$api.get('/sports/events', { params })
        const data = res.data

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SELECTED_EVENT', false)
      }
    },

    async setAddEvent({ commit }, payload) {
      commit('LOAD_ADD_EVENT', true)
      try {
        const res = await this.$api.put('/portal/sports/events/bulk/publish', payload)
        const data = res.data
        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_ADD_EVENT', false)
      }
    },
    async getListAllEventInDB({ commit }, payload) {
      commit('LOAD_SELECTED_EVENT', true)
      try {
        // eslint-disable-next-line no-shadow
        const params = {
          page: payload.page,
          limit: payload.limit,
          startTimeFrom: payload.time,
          startTimeTo: payload.endTime,
          league: payload.league,
          sortField: 'startTime',
          sortValue: 'DESC',
          eventId: payload.eventId,
        }
        const res = await this.$api.get('/sports/events', { params })
        const data = res.data

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_SELECTED_EVENT', false)
      }
    },

    async getListLeaguesSport({ commit }) {
      commit('LOAD_LEAGUES', true)
      try {
        const res = await this.$api.get('/sports/leagues')
        const data = res.data

        return data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_LEAGUES', false)
      }
    },

  },
}
