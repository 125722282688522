import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  detailEvent: null,
  loading: false,
  error: '',
  totalPage: 0,
  loadingChangeSelection: false,
  loadingInputResultAuto: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_DATA: (s, detailEvent) => (s.detailEvent = detailEvent),
    LOAD: (s, data) => (s.loading = data),
    LOAD_CHANGE_SELECTION: (s, data) => (s.loadingChangeSelection = data),
    LOAD_INPUT_RESULT_AUTO: (s, data) => (s.loadingInputResultAuto = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getDetail({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.get(`/portal/sports/events/${params}`)
        const data = res.data.data.event
        const roles = res.data.data.local
        const formatData = {
          ...data,
          ...roles,
        }
        commit('SET_DATA', formatData)

        return formatData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },

    async getListMarketEventDetail({ commit }, params) {
      commit('LOAD', true)
      try {
        const { limit, page, eventId } = params
        const res = await this.$api.get(`/portal/sports/events/${eventId}/market`, {
          params: {
            limit,
            page,
          },
        })

        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },

    async changeSelection({ commit }, payload) {
      commit('LOAD_CHANGE_SELECTION', true)
      try {
        const res = await this.$api.post('/portal/sports/results', payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHANGE_SELECTION', false)
      }
    },
    async recalculateUserTotalPoint({ commit }, payload) {
      commit('LOAD_INPUT_RESULT_AUTO', true)
      try {
        const res = await this.$api.post(`/portal/sports/results/events/${payload}/refresh-user-point`)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_INPUT_RESULT_AUTO', false)
      }
    },
    async inputResultAuto({ commit }, payload) {
      commit('LOAD_INPUT_RESULT_AUTO', true)
      try {
        const res = await this.$api.post(`/portal/sports/results/events/${payload}`)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_INPUT_RESULT_AUTO', false)
      }
    },
    async getListMarketResult({ commit }, params) {
      commit('LOAD', true)
      try {
        const { eventId } = params
        const res = await this.$api.get(`/portal/sports/events/${eventId}/results`)

        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async changeStatusCrawlScore({ commit }, payload) {
      commit('LOAD_INPUT_RESULT_AUTO', true)
      try {
        const res = await this.$api.put(`/portal/sports/events/${payload}/disable-score-status`)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_INPUT_RESULT_AUTO', false)
      }
    },
  },
}
