import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  data: [],
  loading: false,
  error: '',
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    SET_LIST_SEASON: (s, data) => (s.data = data),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/bo/sdp-comps/season', { params })
        const sportData = res.data.data
        commit('SET_LIST_SEASON', sportData)
        return sportData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
