import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loading = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async removeLeagueToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, leagueId } = payload
      try {
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/leagues/${leagueId}`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async removeSportEventsToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, eventId } = payload
      try {
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/sport-events/${eventId}`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async updateSportEventsToComp({ commit }, payload) {
      commit('LOADING')
      const { competitionId, eventId, stadium } = payload
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/sport-events/${eventId}`, {
          stadium,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    // eslint-disable-next-line no-empty-pattern
    async getListMarketOfSportComp({ }, payload) {
      const { competitionId, eventId } = payload
      try {
        const res = await this.$api.get(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },

    async updateLimitEventMarket({ commit }, payload) {
      commit('LOADING')
      const {
        competitionId, eventId, marketId, selectionLimit, marketAlias, marketLayout, imageUrl,
      } = payload
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets/${marketId}`, {
          selectionLimit,
          marketAlias,
          marketLayout,
          imageUrl,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async changeFlagEvent({ commit }, payload) {
      commit('LOADING')
      const {
        eventId, data,
      } = payload
      try {
        const res = await this.$api.post(`/portal/sports/events/${eventId}/sport-roles`, data)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async deleteMarket({ commit }, payload) {
      commit('LOADING')
      const {
        eventId, competitionId, marketId,
      } = payload
      try {
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets/${marketId}`)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async updateMarket({ commit }, payload) {
      commit('LOADING')
      const {
        competitionId, eventId, marketId, ...rest
      } = payload
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/sport-events/${eventId}/markets/${marketId}`, rest)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
