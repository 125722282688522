import MEDIA from '@core/utils/enum/media'
import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')

  if (nameArray.length >= 2) {
    const convertName = nameArray[0].charAt(0) + nameArray[nameArray.length - 1].charAt(0)
    return convertName.toUpperCase()
  }
  return nameArray[0].charAt(0).toUpperCase()

  // return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateShort = (value, formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat(['ban', 'id'], formatting).format(new Date(value))
}

export const formatDateValue = (value, formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
  if (!value) return value
  const date = new Intl.DateTimeFormat(['ban', 'id'], formatting).format(new Date(value))
  return date.split('/').reverse().join('-')
}
export const formatPrice = (
  value,
  formatting = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    roundingIncrement: 5,
  },
) => {
  if (value === '-') return '-'
  return new Intl.NumberFormat('en-US', formatting).format(value)
}

export const formatBetTop = value => {
  if (value === '-' || value === null) return value
  if (value.indexOf('@') > -1) {
    return `$${value.slice(0, value.indexOf('@'))}`
  }
  return `$${value.slice(0, value.indexOf('/'))}`
}
export const formatBetBottom = value => {
  if (value === '-' || value === null) return ''
  if (value.indexOf('@') > -1) {
    return value.slice(value.indexOf('@'), value.length - 1)
  }
  return value.slice(value.indexOf('/'), value.length - 1)
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const urlify = text => {
  if (!text) return []
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.match(urlRegex) || []
}

export const fileType = file => file.type.split('/')[0]

export const validateImageSize = file => file.size / 1024 / 1024 <= MEDIA.IMAGE.maxSize

export const validateVideoSize = file => file.size / 1024 / 1024 <= MEDIA.VIDEO.maxSize
