import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  dataListRace: [],
  tokenBetMaket: null,
  expireAt: null,
  loading: false,
  error: '',
  eventPicked: {},
  racePicked: {},
  listEventPicked: [
    { legId: 1, title: 'LEG 1', value: '' },
    { legId: 2, title: 'LEG 2', value: '' },
    { legId: 3, title: 'LEG 3', value: '' },
    { legId: 4, title: 'LEG 4', value: '' },
    { legId: 5, title: 'LEG 5', value: '' },
    { legId: 6, title: 'LEG 6', value: '' },
    { legId: 7, title: 'LEG 7', value: '' },
    { legId: 1, title: 'LEG 1', value: '' },
    { legId: 2, title: 'LEG 2', value: '' },
    { legId: 3, title: 'LEG 3', value: '' },
    { legId: 4, title: 'LEG 4', value: '' },
    { legId: 5, title: 'LEG 5', value: '' },
    { legId: 6, title: 'LEG 6', value: '' },
    { legId: 7, title: 'LEG 7', value: '' },
  ],
  pickFrontBack: null,
  pickFrontBackNumber: null,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_DATA_LIST_RACE: (s, data) => (s.dataListRace = data),
    SET_DATA_TOKEN: (s, value) => (s.tokenBetMaket = value),
    SET_DATA_TIME_EXPIRED: (s, value) => (s.expireAt = value),
    SET_EVENT_PICKED: (state, data) => (state.eventPicked = data),
    SET_RACE_PICKED: (state, data) => (state.racePicked = data),
    SET_LIST_PICKED: (state, data) => (state.listEventPicked = data),
    SET_FRONT_BACK: (state, data) => (state.pickFrontBack = data),
    SET_FRONT_BACK_NUMBER: (state, data) => (state.pickFrontBackNumber = data),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getTokenBetMaker({ commit }) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get('/bet-maker/access-token', {
          headers: {
            'be-api-key': process.env.VUE_APP_BE_API_KEY,
          },
        })
        const { accessToken, expiresIn, tokenType } = res.data.data
        const expireAt = new Date().getTime() / 1000 + expiresIn
        const token = `${tokenType} ${accessToken}`
        commit('SET_DATA_TOKEN', token)
        window.localStorage.setItem('token', token)
        commit('SET_DATA_TIME_EXPIRED', expireAt)

        return token
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    setPickEvent: ({ commit }, params) => {
      commit('SET_EVENT_PICKED', params)
    },
    setPickRace: ({ commit }, params) => {
      commit('SET_RACE_PICKED', params)
    },
    setPickListEvent: ({ commit }, params) => {
      commit('SET_LIST_PICKED', params)
    },
    setPickFrontBack: ({ commit }, params) => {
      commit('SET_FRONT_BACK', params)
    },
    setPickFrontBackNumber: ({ commit }, params) => {
      commit('SET_FRONT_BACK_NUMBER', params)
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
