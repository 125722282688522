const appsAndPages = [
  {
    header: 'Apps & Pages',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    route: 'users-list',
  },
  // {
  //   title: 'Racing',
  //   icon: 'PieChartIcon',
  //   route: 'racing-list',
  // },
  // {
  //   title: 'Competitions',
  //   icon: 'FeatherIcon',
  //   route: 'comp-list',
  // },
  {
    title: 'Competitions',
    icon: 'FeatherIcon',
    children: [
      {
        title: 'List',
        route: 'comp-list',
      },
      {
        title: 'Settings',
        route: 'setting',
      },
      {
        title: 'Jackpot',
        route: 'jackpot',
      },
      {
        title: 'Sport',
        route: 'sport',
      },
      {
        title: 'Tipping',
        route: 'tipping',
      },
      {
        title: 'YNYM',
        route: 'ynym',
      },
    ],
  },
  {
    title: 'Transactions',
    icon: 'RepeatIcon',
    children: [
      {
        title: 'Withdrawal',
        route: 'withdraw-list',
      },
      {
        title: 'Withdrawal UPCOZ',
        route: 'upcoz-list',
      },
      {
        title: 'Deposit',
        route: 'deposit-list',
      },
      {
        title: 'Transaction',
        route: 'transaction-list',
      },
      {
        title: 'Live Logging',
        route: 'logging-list',
      },
    ],
  },
  // {
  //   title: 'Push Notification',
  //   icon: 'MessageCircleIcon',
  //   route: 'push-notification',
  // },
  // {
  //   title: 'Push Noti Group',
  //   icon: 'MessageCircleIcon',
  //   route: 'group-notification',
  // },
  {
    title: 'Notification',
    icon: 'MessageCircleIcon',
    route: 'notification-view',
  },
]

export default appsAndPages
