import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  userData: null,
  loading: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER_EXPORT: (s, user) => (s.userData = user),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async asyncUserExportCSV({ commit }, payload) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.post('/bo/users/export-csv', payload)
        const userData = res.data.data
        commit('SET_USER_EXPORT', userData)
        return userData
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
