import listSport from './list-sport'
import createSport from './create-sport'
import seasonList from './season-list'
import createSeason from './create-season'
import seasonDetail from './season-detail'
import importCsv from './import-csv'
import listComp from './list-comp'
import pauseComp from './pause-comp'
import createComp from './create-comp'
import updateResultMatch from './update-result-match'
import deleteComp from './delete-comp'
import detailComp from './detail-comp'
import updateComp from './update-comp'
import participantComp from './participant-comp'
import updatePayment from './update-payment'
import deleteSport from './delete-sport'
import deleteSeason from './delete-season'
import displayMatch from './display-match'
import createTeam from './create-team'
import updateTeam from './update-team'
import createMatch from './create-match'
import updateMatch from './update-match'
import submitPayment from './submit-payment'
import publishPrizes from './publish-prizes'
import deleteParticipant from './delete-participant'

const postsModule = {
  namespaced: true,
  modules: {
    listSport,
    createSport,
    deleteSport,
    seasonList,
    createSeason,
    deleteSeason,
    seasonDetail,
    createTeam,
    updateTeam,
    importCsv,
    listComp,
    pauseComp,
    createComp,
    updateResultMatch,
    displayMatch,
    deleteComp,
    detailComp,
    updateComp,
    participantComp,
    updatePayment,
    createMatch,
    updateMatch,
    submitPayment,
    publishPrizes,
    deleteParticipant,
  },
}

export default postsModule
