import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: '',
  isReset: false,

})

export default {
  namespaced: true,
  state: initState(),
  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    SET_DATA_RESET: (s, data) => (s.isReset = data),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async handlePushNotification({ commit }, body) {
      commit('LOADING')
      try {
        const res = await this.$api.post('/portal/notification-v2/manual', body)
        if (res.data.data) return res.data.data
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async handlePushNotificationGroup({ commit }, body) {
      commit('LOADING')
      try {
        const res = await this.$api.post('/portal/notification-v2/groups/manual', body)
        if (res.data.data) return res.data.data
        return false
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    resetListUser({ commit }, data) {
      commit('SET_DATA_RESET', data)
    },

  },
}
