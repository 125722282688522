import { ref, watch, computed } from '@vue/composition-api'

export const STATUS_PAYMENT = {
  INIT: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECT: 3,
  COMPLETED: 4,
  ELIMINATE: 5,
}

export default function usePostList() {
  // Use toast
  const refParticipantTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'avatar', tdClass: 'column-w-200' },
    { key: 'name', tdClass: 'column-md text-left', thClass: 'text-left' },
  ]

  const perPage = ref(5)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const dataMeta = computed(() => {
    const localItemsCount = refParticipantTable.value ? refParticipantTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refParticipantTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  return {
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    refParticipantTable,
  }
}
