import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingListCampaign: false,
  loadingCreateCampaign: false,
  listCampaign: [],
  isCreateSuccess: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingListCampaign = true),
    LOADING_CREATE_CAMPAIGN: state => (state.loadingCreateCampaign = true),
    SET_ERROR: (s, error) => (s.error = error),
    SET_CREATE_SUCCESS: (s, isCreateSuccess) => (s.isCreateSuccess = isCreateSuccess),
    LOADED: s => (s.loadingListCampaign = false),
    LOADED_CREATE_CAMPAIGN: s => (s.loadingCreateCampaign = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListCampaign({ commit }, params) {
      commit('LOADING')
      try {
        const res = await this.$api.get('/portal/coupon-campaigns', { params })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async createCampaign({ commit }, payload) {
      commit('LOADING_CREATE_CAMPAIGN')
      try {
        const res = await this.$api.post('/portal/coupon-campaigns', payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_CAMPAIGN')
      }
    },
    async updateCampaign({ commit }, payload) {
      commit('LOADING_CREATE_CAMPAIGN')
      try {
        const { couponCampaignId } = payload
        const res = await this.$api.put(`/portal/coupon-campaigns/${couponCampaignId}`, payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_CAMPAIGN')
      }
    },
    async deleteCampaign({ commit }, payload) {
      commit('LOADING_CREATE_CAMPAIGN')
      try {
        const res = await this.$api.delete(`/portal/coupon-campaigns/${payload}`)
        if (res.data) return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_CREATE_CAMPAIGN')
      }
    },
    checkCreateCampaign({ commit }, payload) {
      commit('SET_CREATE_SUCCESS', payload)
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
