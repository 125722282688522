import list from './list'
import detail from './detail'
import withdrawList from './withdrawList'
import updateStatus from './update-status'
import log from './log'

const transactionModule = {
  namespaced: true,
  modules: {
    list,
    detail,
    withdrawList,
    updateStatus,
    log,
  },
}

export default transactionModule
