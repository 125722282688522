import { errorHandler } from '@/services/toastHandler'
import { apiFat } from '@/services/api'

const initState = () => ({
  creditCards: [],
  loadingDeleteCard: false,
  loadingDeleteBankAccount: false,

  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USERS: (s, users) => (s.users = users),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loadingDeleteCard: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loadingDeleteCard = false),
    LOAD_DELETE_BANK: (s, loading) => (s.loadingDeleteBankAccount = loading),

    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async handleGetCreditCards(_, params) {
      try {
        const res = await apiFat.get(`/cards/${params}`)
        if (res.data && Object.keys(res.data)) {
          return res.data
        }
        return null
      } catch (e) {
        errorHandler(e)
      }
    },
    async handleGetDeleteCard({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await apiFat.post('/delete-card', payload)
        if (res.data?.success) {
          return res.data
        }
        return null
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async handleGetDeleteBankAccount({ commit }, payload) {
      commit('LOAD_DELETE_BANK', true)
      try {
        const res = await this.$api.delete(`/portal/bank-accounts/${payload}`)
        if (res.data?.data) {
          return res.data.data
        }
        return null
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_DELETE_BANK', false)
      }
    },
  },
}
