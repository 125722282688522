import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingUpdateComp: false,
  loadingAddEntry: false,
  loadingDeleteEntry: false,
  addEntryId: null,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingUpdateComp = true),
    LOADING_ADD_ENTRY: state => (state.loadingAddEntry = true),
    LOADING_DELETE_ENTRY: state => (state.loadingDeleteEntry = true),
    SET_ERROR: (s, error) => (s.error = error),
    SET_ENTRY_ID: (s, addEntryId) => (s.addEntryId = addEntryId),
    LOADED: s => (s.loadingUpdateComp = false),
    LOADED_ADD_ENTRY: s => (s.loadingAddEntry = false),
    LOADED_DELETE_ENTRY: s => (s.loadingDeleteEntry = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async updateCompAsync({ commit }, payload) {
      const { data, competitionId } = payload
      commit('LOADING')
      try {
        await this.$api.put(`/portal/competitions/${competitionId}`, data)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },

    async addEntry({ commit }, payload) {
      commit('LOADING_ADD_ENTRY')
      try {
        const res = await this.$api.post('/portal/entries', payload)
        commit('SET_ENTRY_ID', res.data.data.entryId)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_ENTRY')
      }
    },

    async deleteEntry({ commit }, payload) {
      commit('LOADING_DELETE_ENTRY')
      try {
        await this.$api.delete(`/portal/entries/${payload}`)
        return true
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_DELETE_ENTRY')
      }
    },

    setEntryAddId: ({ commit }, params) => {
      commit('SET_ENTRY_ID', params)
    },

    async addPrize({ commit }, payload) {
      commit('LOADING_ADD_ENTRY')
      try {
        const { competitionId, data } = payload
        const res = await this.$api.post(`/portal/competitions/${competitionId}/prizes`, data)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_ENTRY')
      }
    },

    async deletePrize({ commit }, payload) {
      commit('LOADING_ADD_ENTRY')
      try {
        const { competitionId, data } = payload
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/prizes`, {
          params: data,
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_ENTRY')
      }
    },

    async updatePrizeMode({ commit }, payload) {
      commit('LOADING_ADD_ENTRY')
      try {
        const { competitionId, data } = payload
        const res = await this.$api.put(`/portal/competitions/${competitionId}/prize-mode`, data)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_ENTRY')
      }
    },
    async deleteAllPrizes({ commit }, payload) {
      commit('LOADING_ADD_ENTRY')
      try {
        const { competitionId, type } = payload
        const res = await this.$api.delete(`/portal/competitions/${competitionId}/prizes/remove-all`, {
          params: { type },
        })
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_ADD_ENTRY')
      }
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
