import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  error: '',
  loadingMid: false,
  loadingApproveMid: false,
  loadingUpdateNameWeek: false,
  loadingUpdateRoundBonus: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD_LIST_MID: (state, data) => (state.loadingMid = data),
    LOAD_APPROVE_MID: (state, data) => (state.loadingApproveMid = data),
    LOAD_UPDATE_NAME_WEEK: (state, data) => (state.loadingUpdateNameWeek = data),
    LOAD_UPDATE_ROUND_BONUS: (state, data) => (state.loadingUpdateRoundBonus = data),
  },

  actions: {
    async getListMidPayout({ commit }, params) {
      commit('LOAD_LIST_MID', true)

      const { competitionId, weekNumber } = params
      try {
        const res = await this.$api.get('/portal/competition-users/weekly', {
          params: {
            competitionId,
            weekNumber,
            hasRank: true,
          },
        })

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_LIST_MID', false)
      }
    },
    async approveMidPayout({ commit }, params) {
      commit('LOAD_APPROVE_MID', true)

      const { competitionId, weekNumber } = params
      try {
        const res = await this.$api.post(`/portal/competitions/${competitionId}/prizes/approve-mid-payout`, {
          weekNumber,
        })

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_APPROVE_MID', false)
      }
    },
    async updateNameWeek({ commit }, params) {
      commit('LOAD_UPDATE_NAME_WEEK', true)

      const { competitionId, ...weeks } = params
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/weeks`, weeks)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_UPDATE_NAME_WEEK', false)
      }
    },
    async getListWeek(_, params) {
      const { competitionId, weekNumber, hasBonus } = params
      try {
        const res = await this.$api.get(`/competitions/${competitionId}/weeks`, {
          params: {
            weekNumber,
            hasBonus,
          },
        })

        return res.data.data
      } catch (e) {
        errorHandler(e)
      }
    },
    async updateRoundBonus({ commit }, params) {
      commit('LOAD_UPDATE_ROUND_BONUS', true)

      const { competitionId, ...data } = params
      try {
        const res = await this.$api.put(`/portal/competitions/${competitionId}/perfect-round-bonus`, data)

        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_UPDATE_ROUND_BONUS', false)
      }
    },
  },
}
