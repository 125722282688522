import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loadingEndComp: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: state => (state.loadingEndComp = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loadingEndComp = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async endCompAsync({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await this.$api.post(`/portal/competitions/${payload}/completed`)
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
    async abandonComp({ commit }, payload) {
      commit('LOADING')
      try {
        const res = await this.$api.post(`/portal/competitions/${payload.id}/abandon`, {
          password: payload.password,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
