import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: {},
  success: false,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    SUCCESSED: s => (s.success = true),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async do({ commit, dispatch }, { howlText, media }) {
      commit('RESET')
      commit('LOADING')
      try {
        await Promise.all(
          media.map(async z => {
            const type = media[0].type.split('/')[0] === 'image' ? 1 : 2
            const formData = new FormData()
            formData.append('file', z)
            const { data } = await this.$api.post(
              `/tweets/media?type=${type}`,
              formData,
            )
            return data.data
          }),
        ).then(async medias => {
          await dispatch('_createHowl', {
            howlText,
            medias,
          })
        })
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async _createHowl(
      { commit },
      { howlText, medias },
    ) {
      const payload = {
        tweetType: 1,
        ...(howlText
          ? {
            text: {
              tweetText: howlText,
            },
          }
          : {}),
        medias,
      }

      const res = await this.$api.post('/bo/posts', payload)
      commit('SUCCESSED')
      return res
    },
  },
}
