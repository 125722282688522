import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  limitNumber: null,
  loadingGetLimit: false,
  loadingSetLimit: false,
  error: '',
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_LIMIT_NUMBER: (s, limitNumber) => (s.limitNumber = limitNumber),
    LOADING: state => (state.loadingGetLimit = true),
    LOADING_SET_LIMIT: state => (state.loadingSetLimit = true),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loadingGetLimit = false),
    LOADED_SET_LIMIT: s => (s.loadingSetLimit = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getLimitNumber({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const res = await this.$api.get(`/portal/settings/${params}`)
        const limit = res.data.data.value

        commit('SET_LIMIT_NUMBER', limit)
        return res
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
    async setLimitNumber({ commit }, params) {
      commit('LOADING_SET_LIMIT')
      try {
        const res = await this.$api.put(`/portal/settings/${params.key}`, {
        //   params: {
          value: Number(params.number),
        //   },
        })
        return res
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED_SET_LIMIT')
      }
    },
  },
}
