import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  logData: null,
  error: '',
  loadingLogList: false,
  totalLogs: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_LOG: (s, logData) => (s.logData = logData),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
    SET_LOADING_LOG_LIST: (s, loadingLogList) => (s.loadingLogList = loadingLogList),
    SET_TOTAL_LOG: (s, totalLogs) => (s.totalLogs = totalLogs),
  },

  actions: {
    async getLogList({ commit }, params) {
      commit('SET_LOADING_LOG_LIST', true)
      try {
        const res = await this.$api.get('/portal/logs', { params })
        const dataLog = res.data.data
        commit('SET_LOG', dataLog)
        commit('SET_TOTAL_LOG', res.data.pagination.total)
        return dataLog
      } catch (e) {
        commit('SET_TOTAL_LOG', 0)
        errorHandler(e)
      } finally {
        commit('SET_LOADING_LOG_LIST', false)
      }
    },
  },
}
