import listEvent from './list-event'
import eventDetail from './detail-event'
import createComp from './create-comp'
import updateComp from './update-comp'

const sportCompModule = {
  namespaced: true,
  modules: {
    listEvent,
    eventDetail,
    createComp,
    updateComp,
  },
}

export default sportCompModule
