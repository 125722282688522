import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  loadingChangeStatus: false,
  error: '',
  total: 0,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOAD: (s, data) => (s.loading = data),
    LOAD_CHANGE_STATUS: (s, data) => (s.loadingChangeStatus = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListBack({ commit }, params) {
      commit('LOAD', true)
      try {
        const res = await this.$api.get('/portal/bet-yourself/', { params })
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async changeStatusBackItem({ commit }, payload) {
      commit('LOAD_CHANGE_STATUS', true)
      try {
        const res = await this.$api.put('/portal/bet-yourself/management/action', payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHANGE_STATUS', false)
      }
    },
    async approveAllPendingList({ commit }, payload) {
      commit('LOAD_CHANGE_STATUS', true)
      try {
        const res = await this.$api.put('/portal/bet-yourself/management/accept-bulk', payload)
        return res.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CHANGE_STATUS', false)
      }
    },
    reset({ commit }) {
      commit('RESET')
    },
  },
}
