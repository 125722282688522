import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  error: {},
  success: false,
})

export default {
  namespaced: true,
  state: initState(),

  mutations: {
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    SUCCESSED: s => (s.success = true),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async do({ commit }, body) {
      commit('RESET')
      commit('LOADING')
      try {
        const response = await this.$api.post('/bo/sdp-comps/season/import-csv', body)
        if (!response.data) return
        return response.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
