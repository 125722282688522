import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  loading: false,
  loadingApprove: false,
  loadingCreate: false,
  loadingClear: false,
  error: '',
  total: null,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    LOAD: (state, data) => (state.loading = data),
    LOAD_APPROVE: (state, data) => (state.loadingApprove = data),
    LOAD_CREATE: (state, data) => (state.loadingCreate = data),
    LOAD_CLEAR: (state, data) => (state.loadingClear = data),
    SET_TOTAL: (s, data) => (s.total = data),
    SET_ERROR: (s, error) => (s.error = error),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListFreeBetslipByComp({ commit }, payload) {
      commit('LOAD', true)
      try {
        const res = await this.$api.get('/portal/free-pick', {
          params: {
            ...payload,
          },
        })
        if (!res.data.data) return
        const total = res.data.pagination.total
        commit('SET_TOTAL', total)
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD', false)
      }
    },
    async approveFreeBetslip({ commit }, payload) {
      commit('LOAD_APPROVE', true)
      try {
        const res = await this.$api.put('/portal/free-pick/approve', payload)
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_APPROVE', false)
      }
    },
    async createFreeBetslip({ commit }, payload) {
      commit('LOAD_CREATE', true)
      try {
        const res = await this.$api.post('/portal/free-pick', payload)
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CREATE', false)
      }
    },
    async clearListFreeBetslip({ commit }, payload) {
      commit('LOAD_CLEAR', true)
      try {
        const res = await this.$api.delete('/portal/free-pick', {
          params: payload,
        })
        if (!res.data.data) return
        return res.data.data
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOAD_CLEAR', false)
      }
    },
  },
}
