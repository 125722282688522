import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  posts: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_POSTS_REPORT: (s, posts) => (s.posts = posts),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async load({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const {
          tweetId,
        } = params
        const res = await this.$api.get(`/bo/posts/report/${tweetId}`, { params })

        const { total } = res.data.pagination
        const dataPosts = res.data.data

        commit('SET_POSTS_REPORT', dataPosts)
        commit('SET_TOTAL_PAGE', total)
        return dataPosts
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
