import { errorHandler } from '@/services/toastHandler'

const initState = () => ({
  withdrawList: [],
  loading: false,
  error: '',
  totalPage: 0,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_TRANSACTIONS: (s, withdrawList) => (s.withdrawList = withdrawList),
    SET_TOTAL_PAGE: (s, totalPage) => (s.totalPage = totalPage),
    LOADING: s => Object.assign(s, initState(), { loading: true }),
    SET_ERROR: (s, error) => (s.error = error),
    LOADED: s => (s.loading = false),
    RESET: s => Object.assign(s, initState()),
  },

  actions: {
    async getListWithdraw({ commit }, params) {
      commit('RESET')
      commit('LOADING')
      try {
        const formatParams = {
          ...params,
          withUserUsage: 1,
        }
        const res = await this.$api.get('/portal/withdrawal-requests', {
          params: formatParams,
        })
        if (res.data && Object.keys(res.data).length) {
          const totalUsers = res?.data?.pagination?.total
          commit('SET_TRANSACTIONS', res.data.data)
          commit('SET_TOTAL_PAGE', totalUsers)
          return res.data.data
        }
        return null
      } catch (e) {
        errorHandler(e)
      } finally {
        commit('LOADED')
      }
    },
  },
}
